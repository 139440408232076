<template>
  <div class="landing-steps">
    <aos-vue placement="top-bottom" animation="fade-up" :once="true" :duration="800">
      <div class="landing-steps__content">
        <div class="landing-steps__query">
          Как это работает?
        </div>
        <div class="landing-steps__title">
          4&nbsp;простых шага к&nbsp;вашему автомобилю
        </div>
      </div>
    </aos-vue>
    <aos-vue placement="top-bottom" animation="fade-up" :once="true" :duration="800">
      <Swiper class="landing-steps__slider" slidesPerView="auto">
        <SwiperSlide class="landing-service__slide" v-for="slide, index in slider" :key="index">
          <div class="landing-steps__number">
            {{ index + 1 }}
          </div>
          <div class="landing-steps__subtitle" v-html="slide.subtitle">
          </div>
          <div class="landing-steps__description">
            {{ slide.description }}
          </div>
        </SwiperSlide>
      </Swiper>
    </aos-vue>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from '@SwiperVue';

export default {
  name: 'Steps',
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      slider: [
        {
          subtitle: 'Выберите автомобиль',
          description: 'Более 30 автомобилей в максимальной комплектации',
        },
        {
          subtitle: 'Зарегистрируйтесь',
          description: 'Понадобится только номер телефона',
        },
        {
          subtitle: 'Загрузите документы',
          description: 'Привяжите банковскую карту и оплатите первый месяц',
        },
        {
          subtitle: 'Получите автомобиль',
          description: 'и наслаждайтесь!',
        },
      ],
    };
  }
};
</script>