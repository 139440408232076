<template>
  <div class="call call--promo">
    <div class="call__content">
      <div class="call__description">
        <div class="call__query">Как работают промокоды?
        </div>
        <div class="call__title">Цены в каталоге указаны без учета промокода
        </div>
        <div class="call__text">Вы сможете ввести промокод<br>при бронировании автомобиля
        </div>
      </div>
      <div class="call__bg">
        <picture>
          <source media="(max-width: 767px)" srcset="@/assets/images/about/promo-mobile.webp, @/assets/images/about/promo-mobile@2x.webp 2x" type="image/webp"/>
          <source media="(max-width: 767px)" srcset="@/assets/images/about/promo-mobile.png, @/assets/images/about/promo-mobile@2x.png 2x" type="image/png"/>
          <source media="(min-width: 768px) and (max-width: 1024px)" srcset="@/assets/images/about/promo-tablet.webp, @/assets/images/about/promo-tablet@2x.webp 2x" type="image/webp"/>
          <source media="(min-width: 768px) and (max-width: 1024px)" srcset="@/assets/images/about/promo-tablet.png, @/assets/images/about/promo-tablet@2x.png 2x" type="image/png"/>
          <source srcset="@/assets/images/about/promo.webp, @/assets/images/about/promo@2x.webp 2x" type="image/webp"/><img srcset="@/assets/images/about/promo.png, @/assets/images/about/promo@2x.png 2x" alt="Picture" loading="lazy" width="100%" height="100%"/>
        </picture>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OfferDetail'
};
</script>