import Catalog from '@/pages/Catalog';
import Car from '@/pages/Car';
import Login from '@/pages/Login';
import Landing from '@/pages/landing/Landing';
import Registration from '@/pages/Registration';
import Profile from '@/pages/Profile';
import Scoring from '@/pages/Scoring';
import Error404 from '@/pages/Error404';
import LandingUl from '@/pages/landingUl/Landing';
import CatalogUl from '@/pages/CatalogUl';
import CarUl from '@/pages/CarUl';
import constants from './constants';

const routes = [
  {
    path: '/',
    component: Landing,
    name: constants.Landing.name,
    meta: {
      description: constants.Landing.description,
      keywords: constants.Landing.keywords
    }
  },
  {
    path: '/ul/',
    component: LandingUl,
    name: constants.LandingUl.name,
    meta: {
      description: constants.LandingUl.description,
      keywords: constants.LandingUl.keywords
    }
  },
  {
    path: '/ul/catalog',
    component: () => import('../layouts/MainLayoutUl.vue'),
    children: [
      {
        path: '/ul/catalog',
        component: CatalogUl,
        name: constants.CatalogUl.name,
        meta: {
          description: constants.CatalogUl.description,
          keywords: constants.CatalogUl.keywords
        }
      },
      {
        path: '/ul/car/:id',
        component: CarUl,
        name: constants.CarUl.name,
        meta: {
          description: constants.CarUl.description,
          keywords: constants.CarUl.keywords
        }
      },
    ],
  },
  {
    path: '/catalog',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      {
        path: '/catalog',
        component: Catalog,
        name: constants.Catalog.name,
        meta: {
          description: constants.Catalog.description,
          keywords: constants.Catalog.keywords
        }
      },
      {
        path: '/car/:id',
        component: Car,
        name: constants.Car.name,
        meta: {
          description: constants.Car.description,
          keywords: constants.Car.keywords
        }
      },
      {
        path: '/login',
        component: Login,
        name: constants.Login.name,
        meta: {
          description: constants.Login.description,
          keywords: constants.Login.keywords
        }
      },
      {
        path: '/registration',
        component: Registration,
        props: (route) => ({ query: route.query.q }),
        name: constants.Registration.name,
        meta: {
          description: constants.Registration.description,
          keywords: constants.Registration.keywords
        }
      },
      {
        path: '/profile',
        component: Profile,
        name: constants.Profile.name,
        meta: {
          requiresAuth: true,
          description: constants.Profile.description,
          keywords: constants.Profile.keywords
        }
      },
      {
        path: '/scoring/:step?',
        component: Scoring,
        name: constants.Scoring.name,
        meta: {
          requiresAuth: true,
          description: constants.Scoring.description,
          keywords: constants.Scoring.keywords
        }
      },
      {
        path: '/booking',
        redirect: () => {
          return '/scoring/final';
        },
        meta: { requiresAuth: true }
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: Error404
  }
];

export default routes;