<template>
  <div class="card">
    <img src="@/assets/images/catalog/card-placeholder.svg" alt="">
  </div>
</template>

<script>
export default {
  name: 'CardPlaceholder',
};
</script>