<template>
  <transition name="fade">
    <div v-if="isShow" class="landing-popup">
      <div class="landing-popup__overlay"></div>
      <div class="landing-popup__container"
        :class="{ 'thanks': typePopup === 'thanks', 'information': typePopup === 'information', 'error-requisition': typePopup === 'errorRequisition' }"
        ref="popupBody">
        <button class="landing-popup__close" type="button" @click="closePopup()">
          <svg>
            <use xlink:href="@/assets/images/sprites.svg#icon-close"></use>
          </svg>
        </button>
        <div class="landing-popup__content">

          <template v-if="typePopup === 'call'">
            <div class="landing-popup__title">
              Заявка для юр.лиц
            </div>
            <div class="landing-popup__text">
              Мы обязательно с Вами свяжемся
            </div>
            <form class="landing-popup__form" @submit.prevent="formSend">
              <input class="landing-popup__input" :class="{ 'is-error': v$.inn.$error }" v-model="inn" type="text"
                name="inn" placeholder="ИНН" />
              <input class="landing-popup__input" :class="{ 'is-error': v$.name.$error }" v-model="name" type="text"
                name="name" placeholder="Имя" />
              <input class="landing-popup__input" :class="{ 'is-error': v$.email.$error }" v-model="email" type="text"
                name="email" placeholder="E-mail" />
              <input class="landing-popup__input" :class="{ 'is-error': v$.phone.$error }" v-model="phone" type="tel"
                name="phone" placeholder="Телефон" />
              <div class="landing-popup__note">
                <span class="landing-popup__span">Нажав на кнопку, соглашаюсь на обработку&nbsp;</span>
                <a target="_blank" href="https://api.autopodpiska.ru/document/personal-data-consent" class="landing-popup__link">персональных данных</a>
              </div>
              <div class="landing-popup__button">
                <Button type="submit" name="Оставить заявку"></Button>
              </div>
            </form>
          </template>

          <template v-if="typePopup === 'callJur'">
            <div class="landing-popup__title">
              Заявка для юр.лиц
            </div>
            <div class="landing-popup__text">
              Мы обязательно с Вами свяжемся
            </div>
            <form class="landing-popup__form" @submit.prevent="formSend">
              <input class="landing-popup__input" :class="{ 'is-error': v$.inn.$error }" v-model="inn" type="text"
                name="inn" placeholder="ИНН" />
              <input class="landing-popup__input" :class="{ 'is-error': v$.name.$error }" v-model="name" type="text"
                name="name" placeholder="Имя" />
              <input class="landing-popup__input" :class="{ 'is-error': v$.email.$error }" v-model="email" type="text"
                name="email" placeholder="E-mail" />
              <input class="landing-popup__input" :class="{ 'is-error': v$.phone.$error }" v-model="phone" type="tel"
                name="phone" placeholder="Телефон" />
              <div class="landing-popup__note">
                <span class="landing-popup__span">Нажав на кнопку, соглашаюсь на обработку&nbsp;</span>
                <a target="_blank" href="https://api.autopodpiska.ru/document/personal-data-consent" class="landing-popup__link">персональных данных</a>
              </div>
              <div class="landing-popup__button">
                <Button type="submit" name="Оставить заявку"></Button>
              </div>
            </form>
          </template>

          <template v-if="typePopup === 'thanks'">
            <div class="landing-popup__icon">
              <svg>
                <use xlink:href="@/assets/images/sprites.svg#icon-check"></use>
              </svg>
            </div>
            <div class="landing-popup__title">
              Ваше сообщение успешно отправлено!
            </div>
            <div class="landing-popup__text">
              Наш менеджер свяжется с&nbsp;вами
            </div>
            <div class="landing-popup__button">
              <Button @click="closePopup()" name="Спасибо!"></Button>
            </div>
          </template>

          <template v-if="typePopup === 'errorRequisition'">
            <div class="landing-popup__icon">
              <svg>
                <use xlink:href="@/assets/images/sprites.svg#icon-error"></use>
              </svg>
            </div>
            <div class="landing-popup__title">
              Упс, что-то пошло не так.
            </div>
            <div class="landing-popup__text">
              Пожалуйста, позвоните нам: 8 (499) 270-07-60
            </div>
            <div class="landing-popup__button">
              <Button @click="closePopup()" name="Закрыть"></Button>
            </div>
          </template>

          <template v-if="typePopup === 'information'">
            <div class="landing-popup__content">
              <div class="landing-popup__title">
                Как работает сервис
              </div>
              <div class="landing-popup__video">
                <YoutubeIframe width="100%" height="100%" video-id="A-IFJLKcsww" />
              </div>
            </div>

          </template>
        </div>
      </div>

    </div>
  </transition>
</template>

<script>
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { YoutubeIframe } from '@vue-youtube/component';
import useVuelidate from '@vuelidate/core';
import { required, email, requiredIf } from '@vuelidate/validators';
import Button from './Button';
import * as links from '@/store/links';
import {mapGetters} from 'vuex';

const checkPhone = (val) => (/^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/).test(val);

export default {
  name: 'PopupUl',
  components: {
    Button,
    YoutubeIframe
  },
  props: {
    isShow: {
      type: Boolean,
    }
  },
  computed: {
    ...mapGetters({
      typePopup: 'callUl/type',
      carIdPopup: 'callUl/carId'
    }),
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  validations() {
    return {
      phone: {
        required, checkPhone
      },
      name: { required: requiredIf(() => this.typePopup === 'callJur') },
      inn: { required: requiredIf(() => this.typePopup === 'callJur') },
      email: { email, required: requiredIf(() => this.typePopup === 'callJur') }
    };
  },
  watch: {
    isShow(val) {
      if (val) {
        disablePageScroll();
      } else {
        enablePageScroll();
      }
    }
  },
  methods: {
    async formSend() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) return;

      const data = {
        inn: this.inn,
        phone: this.phone,
        email: this.email,
        name: this.name ? this.name : this.phone,
        url: window.location.href
      };

      if (this.carIdPopup !== 0) {
        data.car_id = this.carIdPopup.toString();
      }

      this.axios.post(
        `${links.API}/back-call/legal-entity`,
        {
          ...data
        },
      )
        .then(async () => {
          this.closePopup('thanks');
        })
        .catch((error) => {
          console.error(error);
          this.closePopup('errorRequisition');
        });
    },
    dataReset() {
      this.phone = '';
      this.name = '';
      this.inn = '';
      this.email = '';
    },
    closePopup(type) {
      this.v$.$reset();
      this.$store.dispatch('callUl/show', {show: false, type: '', carId: 0});
      this.dataReset();

      if (type) {
        setTimeout(() => {
          this.$store.dispatch('callUl/show', {show: true, type: type, carId: 0});
        }, 300);
      }
    }
  },
  data() {
    return {
      phone: '',
      name: '',
      email: '',
      inn: '',
      url: ''
    };
  }
};
</script>