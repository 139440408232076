<template>
  <div class="filter">
    <button class="filter__close" @click="closeFilters">
      <svg>
        <use xlink:href="@/assets/images/sprites.svg#icon-close"></use>
      </svg>
    </button>
    <h2 class="filter__title">Параметры поиска</h2>
    <button class="filter__reset for-desktop" @click="cancelFilters">Сбросить</button>

    <form class="filter__form">
      <h3 class="filter__head">Доступность</h3>
      <div class="filter__line" v-for="item in availableFilters.statuses" :key="item.id">
        <input v-model="filters[item.id]" class="filter__input" :id="item.id" type="checkbox" :name="item.id"/>
        <label class="filter__checkbox" :for="item.id">
          <span class="filter__text">{{ item.label }}</span>
          <span class="filter__total">{{ item.value }}</span>
        </label>
      </div>
    </form>

    <form class="filter__form">
      <h3 class="filter__head">Цена в месяц</h3>
      <div class="filter__line" v-for="segment in availableFilters.segments" :key="segment.label">
        <input v-model="filters.segments" class="filter__input" :id="segment.label + segment.id" type="checkbox" name="price" :value="segment.id"/>
        <label class="filter__checkbox" :for="segment.label + segment.id">
          <span class="filter__text">{{ segment.label }}</span>
          <span class="filter__total">{{ segment.count }}</span>
        </label>
      </div>
    </form>

    <form class="filter__form">
      <h3 class="filter__head">Марка</h3>
      <div class="filter__line" v-for="model in availableFilters.models" :key="model.name">
        <input v-model="filters.models" class="filter__input" :id="model.name.toLowerCase()" type="checkbox" :name="model.name.toLowerCase()" :value="model.id"/>
        <label class="filter__checkbox" :for="model.name.toLowerCase()">
          <span class="filter__text">{{ model.name }}</span>
          <span class="filter__total">{{ model.count }}</span>
        </label>

        <div v-if="model.classes.length" class="filter__subline">
          <div v-for="rclass in model.classes" class="filter__line" :key="rclass.brief">
            <input v-model="filters.rent_classes" class="filter__input" :id="rclass.brief + rclass.id" type="checkbox" :name="rclass.brief" :value="rclass.id"/>
            <label class="filter__checkbox" :for="rclass.brief + rclass.id">
              <span class="filter__text">{{ rclass.brief }}</span>
              <span class="filter__total">{{ rclass.count }}</span>
            </label>
          </div>
        </div>
      </div>
    </form>

    <div class="filter__bottom">
      <div class="filter__button">
        <button class="button" type="button" @click="applyFilters">Показать</button>
        <button class="button button--drop" type="button" @click="dropFilters">Сбросить</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import debounce from 'lodash/debounce';

export default {
  name: 'CatalogFilter',
  data() {
    return {
      filters: {
        rent_classes: [],
        models: [],
        segments: [],
        is_not_available: false,
        is_available: false
      },
      segmentLabels: {
        0: 'Стандарт',
        1: 'Комфорт',
        2: 'Премиум',
      },
      showAllCars: true,
      rentClassModels: {},
      modelsRentClasses: {},
      availableFilters: {
        models: [],
        segments: [],
        statuses: []
      },
      dictsIsSet: false,
      queryIsSet: false
    };
  },
  computed: {
    activeFilters() {
      const segment = this.filters.segments.length ? 1 : 0;
      const model = this.filters.models.length ? 1 : 0;
      const status = this.filters.is_available || this.filters.is_not_available ? 1 : 0;

      return segment + model + status;
    },
    ...mapGetters({
      cars: 'cars/cars',
      fetchCars: 'cars/fetchCars',
      counts: 'cars/counts',
      classes: 'cars/classes',
      models: 'cars/models',
    }),
  },
  watch: {
    activeFilters(val) {
      this.$emit('showFiltersCount', val);
    },
    'filters.models'(newVal, oldVal) {
      newVal.forEach((model) => {
        const rentClasses = this.modelsRentClasses[model];
        if (!oldVal.includes(model)) {
          const addClasses = rentClasses.some((rentClass) => !this.filters.rent_classes.includes(rentClass));
          if (addClasses) {
            this.filters.rent_classes.push(...rentClasses);
          }
        }
      });

      oldVal.forEach((model) => {
        const rentClasses = this.modelsRentClasses[model];
        if (!newVal.includes(model)) {
          this.filters.rent_classes = this.filters.rent_classes.filter(rentClass => !rentClasses.includes(rentClass));
        }
      });
    },
    'filters.rent_classes'(newVal, oldVal) {
      newVal.forEach((rentClass) => {
        const model = this.rentClassModels[rentClass];
        if (!oldVal.includes(rentClass) && !this.filters.models.includes(model)) {
          this.filters.models.push(model);
        }
      });

      this.filters.models = this.filters.models.filter((model) => {
        const rentClasses = this.modelsRentClasses[model];
        return rentClasses.some((rentClass) => newVal.includes(rentClass));
      });
    },
    'filters.segments'(val) {
      if (val) {
        this.showAllCars = true;
      }
    },
    filters: {
      handler(val) {
        if(this.queryIsSet) {
          this.debouncedQuery(val);
        }
      },
      deep: true
    }
  },
  async mounted() {
    if (this.$route.query.segment) {
      this.showAllCars = false;
    }

    await this.setAvailableFilters();
    this.setQueryFilters();
  },
  methods: {
    debouncedQuery: debounce(function (newval) {
      this.filterCarsRequest(newval);
    }, 300),
    async filterCarsRequest(data) {
      await this.$store.dispatch('cars/fetchCars', data);
      this.$store.dispatch('cars/setCars', this.fetchCars);
      await this.setAvailableFilters();
    },
    setQueryFilters() {
      const query = this.$route.query;
      const queryClass = Number(query.rclass);
      const querySegment = query.segment;

      switch (querySegment) {
        case 'premium':
          this.filters.segments.push(2);
          break;
        case 'comfort':
          this.filters.segments.push(1);
          break;
        case 'standart':
          this.filters.segments.push(0);
          break;
      }

      if (queryClass) {
        const model = this.rentClassModels[queryClass];
        this.filters.rent_classes.push(queryClass);
        this.filters.models.push(model);
      }

      this.$nextTick(() => {
        this.queryIsSet = true;
      });
    },
    async setModels(models) {
      Object.entries(this.counts.model).forEach(async ([modelId, rentClasses]) => {
        const existModel = models.find((el) => el.id === Number(modelId));
        if (!this.models.find(el => el.id === Number(modelId))) {
          await Promise.all([
            this.$store.dispatch('cars/fetchModels'),
            this.$store.dispatch('cars/fetchClasses')
          ]);
          this.dictsIsSet = false;
        }

        const name = this.models.find(el => el.id === Number(modelId)).name;

        const model = existModel || {
          id: Number(modelId),
          name: name,
          classes: [],
          count: 0
        };

        Object.entries(rentClasses).forEach(async ([rentClassId, classCount]) => {
          if (rentClassId === '0') {
            model.count = classCount;
          } else {
            if (!this.classes.find(el => el.id === Number(rentClassId))) {
              await this.$store.dispatch('cars/fetchClasses');
              this.dictsIsSet = false;
            }

            const classData = this.classes.find(el => el.id === Number(rentClassId));
            const existClass = model.classes.find((el) => el.id === Number(rentClassId));

            if(existClass) {
              existClass.count = classCount;
            } else if (classData) {
              if(this.showAllCars || classCount) {
                model.classes.push({...classData, count: classCount});
              }
            }

            if(!this.dictsIsSet) {
              this.rentClassModels[rentClassId] = Number(modelId);
              this.modelsRentClasses[modelId] = this.modelsRentClasses[modelId] || [];
              this.modelsRentClasses[modelId].push(Number(rentClassId));
            }
          }
        });

        if(!existModel) {
          if(this.showAllCars || model.count) {
            models.push(model);
          }
        }
      });

      this.dictsIsSet = true;
    },
    setSegments(segments) {
      Object.entries(this.counts.segment).forEach(([segmentId, segmentCount], index) => {
        segments[index] = {
          id: Number(segmentId),
          count: segmentCount,
          label: this.segmentLabels[segmentId],
        };
      });
    },
    setStatus(statuses) {
      statuses[0] = { value: this.counts.is_available, label: 'В наличии', id: 'is_available'};
      statuses[1] = { value: this.counts.is_not_available, label: 'В поставке', id: 'is_not_available'};
    },
    async setAvailableFilters() {
      this.setSegments(this.availableFilters.segments);
      this.setStatus(this.availableFilters.statuses);
      await this.setModels(this.availableFilters.models);
    },
    dropFilters() {
      this.cancelFilters();
      this.closeFilters();
    },
    applyFilters() {
      this.closeFilters();
    },
    closeFilters() {
      this.$emit('closeFiltersDialog');
    },
    cancelFilters() {
      this.filters = {
        rent_classes: [],
        models: [],
        segments: [],
        is_not_available: false,
        is_available: false
      };
      this.$router.replace('/catalog');
    }
  }
};
</script>