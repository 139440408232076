<template>
  <button @click="swiper.slidePrev()" class="card__prev">
    <svg width="6" height="12" viewBox="0 0 6 12" fill="none">
      <path d="M6 0L-3.00391e-07 6L6 12V6V0Z" fill="black"></path>
    </svg>
  </button>
</template>
<script>
export default {
  props: {
    swiper: {
      type: Object
    }
  },
};
</script>