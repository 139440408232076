<template>
  <div class="landing-intro">
    <div class="landing-intro__content">
      <div class="landing-intro__title">
        <div class="landing-intro__primary">Новая машина.</div>
        <div class="landing-intro__one is-hidden">
          <span v-for="item, index in one" :key="index" v-html="item">
          </span>
        </div>
        <div class="landing-intro__two is-hidden">
          <span v-for="item, index in two" :key="index" v-html="item">
          </span>
        </div>
        <div class="landing-intro__three is-hidden">
          <span v-for="item, index in three" :key="index" v-html="item">
          </span>
        </div>
      </div>
      <div class="landing-intro__text">
        Оптимальный способ владеть новым автомобилем за&nbsp;ежемесячный платеж
      </div>
      <div class="landing-intro__button-container">
        <div class="landing-intro__button">
          <Button href="#catalog" @click.prevent="scrollTo($event); goalEvent('1st_button')" name="Выбрать авто"></Button>
        </div>
        <div class="landing-intro__button">
          <Button @click="changePopupState" modifier="information" name="Как работает?"></Button>
        </div>
      </div>
    </div>
    <div ref="circle" class="landing-intro__circle"></div>
    <div class="landing-intro__element">
      <div ref="car" class="landing-intro__car">
        <Picture mobile tablet :path="`cars/${utmForImageUrl}/car`" ext="png">
        </Picture>
        <div ref="wheel" :class="['landing-intro__wheel', utmForImageUrl]">
          <Picture :path="`cars/${utmForImageUrl}/wheel`" ext="png">
          </Picture>
        </div>
      </div>
    </div>
    <div class="landing-intro__service">
      <div class="landing-intro__query">
        Почему АВТОDOM подписка
      </div>
      <Swiper class="landing-intro__slider " slidesPerView="auto">
        <SwiperSlide :class="`landing-intro__slide landing-intro__slide--${index + 1}`" v-for="slide, index in slider" :key="index">
          <div class="landing-intro__icon">
            <Picture :path="`icons/service-${slide.icon}`" ext="png" name="Icon"></Picture>
          </div>
          <div class="landing-intro__subtitle" v-html="slide.text">
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
    <a class="landing-intro__tg" href="https://t.me/AvtodomPodpiska_Bot" target="_blank">
      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.9002 28.3177L32.3683 20.6041C34.1907 19.8006 40.3711 17.2294 40.3711 17.2294C40.3711 17.2294 43.2236 16.1045 42.9859 18.8364C42.9067 19.9613 42.2728 23.8985 41.6389 28.1632L39.658 40.7843C39.658 40.7843 39.4995 42.6323 38.1525 42.9537C36.8055 43.2751 34.5869 41.8288 34.1907 41.5074C33.8738 41.2664 28.248 37.6506 26.1879 35.8829C25.6332 35.4008 24.9993 34.4366 26.2671 33.3117C29.1196 30.6602 32.5268 27.3659 34.5869 25.2768C35.5377 24.3126 36.4886 22.0628 32.5268 24.7947L21.3484 32.4155C21.3484 32.4155 20.0806 33.219 17.7035 32.4959C15.3264 31.7727 12.5531 30.8085 12.5531 30.8085C12.5531 30.8085 10.6515 29.6033 13.9002 28.3177Z" fill="currentColor"/>
      </svg>
    </a>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { Swiper, SwiperSlide } from '@SwiperVue';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import Button from './Button';
import Picture from './Picture';

export default {
  name: 'Intro',
  components: {
    Button,
    Picture,
    Swiper,
    SwiperSlide
  },
  emits: [
    'changePopupState',
  ],
  data() {
    return {
      introTitleIndex: 1,
      title: undefined,
      one: ['П', 'о&nbsp;', 'п', 'о', 'д', 'п', 'и', 'с', 'к', 'е'],
      two: ['Б', 'е', 'з&nbsp;', 'з', 'а', 'б', 'о', 'т'],
      three: ['С', 'е', 'й', 'ч', 'а', 'с'],
      utmList: [
        'geely_tugella',
        'haval_jolion',
        'exeed_txl',
        'omoda_c5',
      ],
      slider: [
        {icon: 8, text: 'Доступен<br>тест-драйв'},
        {icon: 9, text: 'Максимальные<br>комплектации'},
        {icon: 10, text: 'Большой выбор<br>авто в&nbsp;наличии'},
        {icon: 11, text: 'Доступен весь автопарк<br>АВТОDOM и&nbsp;АСЦ'},
      ],
    };
  },
  computed: {
    utmForImageUrl() {
      if (this.utmList.includes(this.$route.query.utm_replace)) {
        return this.$route.query.utm_replace;
      }
      return this.utmList[this.getRandom(0, this.utmList.length - 1)];
    }
  },
  created() {
    gsap.registerPlugin(ScrollToPlugin);
  },
  mounted() {
    this.introInit();
  },
  beforeUnmount() {
    this.introTitleDestroy();
  },
  methods: {
    goalEvent(goalName) {
      if (this.$metrika) {
        this.$metrika.reachGoal(goalName);
      }
    },
    changePopupState() {
      this.$emit('changePopupState', 'information');
    },
    scrollTo(event) {
      const href = event.target.getAttribute('href');
      const selector = document.querySelector(href);

      gsap.timeline().to(window, {
        duration: 1.2,
        scrollTo: selector,
        ease: 'Power3.ease',
      });
    },
    getRandom(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    introInit() {
      const introTitleInit = this.introTitleInit;
      const tl = gsap.timeline();

      const header = document.querySelector('header');
      header.classList.add('is-hidden');

      tl
        .to(this.$refs.circle, {
          onStart() {
            window.scrollTo(0, 0);
            disablePageScroll();
          },
          onComplete() {
            introTitleInit();
          },
          duration: 2,
          ease: 'power4.out',
          scale: '1',
        })
        .from(this.$refs.car, {
          duration: 1,
          ease: 'power2.out',
          x: '100%',
          clearProps: 'all',
        })
        .from(this.$refs.wheel, {
          onComplete() {
            enablePageScroll();
          },
          duration: 1,
          ease: 'power2.out',
          rotate: '360',
          clearProps: 'all',
        }, '<')
        .from(header, {
          onStart() {
            header.classList.remove('is-hidden');
          },
          duration: 0.3,
          ease: 'power2.out',
          y: '-100%',
          clearProps: 'all',
        });
    },
    introTitleInit() {
      const tl = gsap.timeline();
      const introTitleInit = this.introTitleInit;

      if (this.introTitleIndex === 1) {
        this.title = document.querySelector('.landing-intro__one');
        this.introTitleIndex = 2;
      } else if (this.introTitleIndex === 2) {
        this.title = document.querySelector('.landing-intro__two');
        this.introTitleIndex = 3;
      } else if (this.introTitleIndex === 3) {
        this.title = document.querySelector('.landing-intro__three');
        this.introTitleIndex = 1;
      }

      if (!this.title) {
        return;
      }

      const span = this.title.querySelectorAll('span');

      span.forEach((el) => {
        const title = this.title;
        tl
          .from(el, {
            onStart() {
              title.classList.remove('is-hidden');
            },
            duration: 0.2,
            ease: 'power2.out',
            opacity: 0,
            x: '150%',
            clearProps: 'all',
          });
      });

      const title = this.title;

      tl.to(this.title, {
        onComplete() {
          introTitleInit();
          title.classList.add('is-hidden');
        },
        delay: 0.9,
        duration: 0.4,
        opacity: 0,
        x: '-50',
        clearProps: 'all',
      });
    },
    introTitleDestroy() {
      const titleOne = document.querySelector('.landing-intro__one') ?? false;
      const titleTwo = document.querySelector('.landing-intro__two') ?? false;
      const titleThree = document.querySelector('.landing-intro__three') ?? false;
      const span = document.querySelectorAll('.landing-intro__one span, .landing-intro__two span, .landing-intro__three span');

      span.forEach((el) => {
        gsap.killTweensOf(el);
      });

      gsap.killTweensOf([titleOne, titleTwo, titleThree]);
      
      titleOne.classList.add('is-hidden');
      titleTwo.classList.add('is-hidden');
      titleThree.classList.add('is-hidden');
    }
  }
};
</script>