import '/src/assets/stylesheets/app.scss';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueYandexMetrika from 'vue3-yandex-metrika';
// import VueJivosite from '@bchteam/vue-jivosite'
import Maska from 'maska';
import AosVue from 'aos-vue';
import { createManager } from '@vue-youtube/core';

store.dispatch('auth/init').then(() => {
  createApp(App)
    .use(store)
    .use(router)
    .use(AosVue)
    .use(createManager())
    // .use(VueJivosite, { widgetId: '8RzAUydhWx' })
    .use(VueYandexMetrika, {
      id: 79281940,
      env: 'production',
      router: router,
      options: {
        // defer: true,
        clickmap: true,
        trackLinks: true,
        webvisor: true,
        accurateTrackBounce: true,
        trackHash: true,
        ecommerce: 'dataLayer',
        triggerEvent: true
      },
    })
    .use(VueAxios, axios)
    .use(Maska)
    .mount('#app');
});