<template>
  <div class="profile__promocode">
    <fieldset class="profile__fieldset" :class="{error: isError, success: isSuccess}">
      <legend class="profile__legend">Промокод</legend>
      <input
        class="profile__input"
        type="text"
        name="promocode"
        placeholder="Введите промокод"
        @input="isDiscountEntered = true"
        ref="promoCode"
      />
      <div class="profile__error" :class="{active: isError}">Неверный промокод</div>
      <div class="profile__success" :class="{active: isSuccess}">Промокод применен</div>
      <button
        v-if="isDiscountEntered"
        class="profile__promocode--button button button--border"
        type="button" :disabled="loading"
        @click="checkDiscountCode"
      >
        Применить
      </button>
      <button
          v-if="isCanCancelDiscount"
          class="profile__promocode--button button button--border"
          type="button" :disabled="loading"
          @click="cancelDiscountCode"
      >
        Отменить
      </button>
    </fieldset>
  </div>
</template>

<script>
import * as links from '@/store/links';

export default {
  name: 'ScoringPromoCode',
  data() {
    return {
      isDiscountEntered: false,
      isCanCancelDiscount: false,
      priceWithDiscount: null,
    };
  },
  props: {
    isError: {
      type: Boolean,
      default: false
    },
    isSuccess: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number
    },
    ratePlan: {
      type: Object
    }
  },
  methods: {
    setDiscountId (id) {
      this.$emit('setDiscountId', id);
    },
    checkDiscountCode() {
      const formData = {
        car_id: this.id.toString(),
        promo_code: this.$refs.promoCode.value
      };

      this.axios.post(
          `${links.API}/user/booking/promo`,
          formData
      )
      .then(async(response) => {
        if (response.data.success) {
          this.isDiscountEntered = false;
          this.isCanCancelDiscount = true;
          this.$emit('toggleStatus', false);
          this.setDiscountId(response.data.promo_id);
          this.priceWithDiscount = this.getPriceWithPromoCode(response.data);
          this.$emit('getPriceWithDiscount', this.priceWithDiscount);
        }
        else {
          this.$emit('toggleStatus', true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    },
    getPriceWithPromoCode (response) {
      if (response.percents ==='Fixed') {
        return ((this.ratePlan.months * this.ratePlan.price)- response.discount)/this.ratePlan.months;
      }
      if (response.percents ==='Percent') {
        return (this.ratePlan.price - this.ratePlan.price / 100 * response.discount);
      }
    },
    cancelDiscountCode () {
      this.isDiscountEntered = false;
      this.isCanCancelDiscount = false;
      this.$refs.promoCode.value = '';
      this.$emit('cancelDiscount');
    },
  }
};
</script>
