import axios from 'axios';
import * as links from '../../store/links';
import * as types from '../mutation-types';
import router from '@/router';

const namespaced = true;

// state
const state = {
  token: null,
  user: null,
  rclass: null,
};

// getters
const getters = {
  token: state => state.token,
  user: state => state.user,
  rclass: state => state.rclass,
};

// mutations
const mutations = {
  [types.SAVE_TOKEN](state, {token}) {
    state.token = token;
    localStorage.setItem('auth.token', token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },

  [types.SAVE_USER](state, payload) {
    state.user = payload;
    localStorage.setItem('auth.user', JSON.stringify(payload));
  },

  [types.SAVE_RCLASS](state, payload) {
    state.rclass = payload;
    if (payload) {
      localStorage.setItem('auth.rclass', payload);
    } else {
      localStorage.removeItem('auth.rclass', payload);
    }
  },

  [types.FETCH_USER_SUCCESS](state, user) {
    state.user = user;
  },

  [types.LOGOUT](state) {
    state.token = null;
    state.user = null;
    localStorage.removeItem('auth.token');
    axios.defaults.headers.common['Authorization'] = null;
  },
};

// actions
const actions = {
  saveToken({commit}, payload) {
    commit(types.SAVE_TOKEN, payload);
  },

  saveUser({commit}, payload) {
    commit(types.SAVE_USER, payload);
  },

  saveRclass({commit}, payload) {
    commit(types.SAVE_RCLASS, payload);
  },

  async fetchUser({commit}) {
    try {
      const {data} = await axios.get(`${links.API}/user`, {loading: {message: 'Loading: User...'}});
      commit(types.FETCH_USER_SUCCESS, data);
    } catch (e) {
      commit(types.LOGOUT);
    }
  },

  async fetchRclass({commit}) {
    try {
      let rclass = localStorage.getItem('auth.rclass');
      if (rclass) {
        commit(types.SAVE_RCLASS, rclass);
      }
    } catch (e) {
      commit(types.LOGOUT);
    }
  },

  async logout({commit}) {
    commit(types.LOGOUT);
  },

  init({commit, dispatch}) {
    let token = localStorage.getItem('auth.token');
    console.info('auth::init');
    if (token) {
      commit(types.SAVE_TOKEN, {token});
      dispatch('fetchUser');
      return true;
    }
    return false;
  },

  checkAuth({dispatch}, payload) {
    if (payload.response.data.message === 'invalid or expired jwt') {
      dispatch('logout');
      router.push('/registration');
    }
  }
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};