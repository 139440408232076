<template>
  <main class="home" role="main">
    <section class="home__section home__section--catalog" id="catalog">
      <div class="catalog">
        <div v-if="!loading" class="catalog__filter" :class="{ 'is-active': showFilters }">
          <catalog-filter
            @closeFiltersDialog="closeFilters"
            @showFiltersCount="showFiltersCount"
          >
          </catalog-filter>
        </div>
        <div class="catalog__cards">
          <div class="catalog__selection">
            <div class="catalog__search" @click="openFilters">
              Параметры поиска&nbsp;<span>({{ filtersCount }})</span>
            </div>
            <catalog-select @sortCards="sortCards"></catalog-select>
          </div>
          <transition-group name="fade">
            <template v-if="!loading">
              <card-ul
                v-for="card in cards.slice(0, limit)"
                :card="card"
                :rclass="getClass(card.rent_class_id)"
                :key="card.id"
              ></card-ul>
            </template>
            <template v-else>
              <card-placeholder v-for="n in 8" :key="n"></card-placeholder>
            </template>
          </transition-group>
          <card-call-ul></card-call-ul>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { mapGetters } from 'vuex';
import CardUl from '@/components/CardUl';
import CardCallUl from '@/components/CardCallUl';
import CatalogFilter from '@/components/CatalogFilter';
import CatalogSelect from '@/components/CatalogSelect';
import CardPlaceholder from '@/components/CardPlaceholder';
import { bottomOfElement } from '@/utils';

export default {
  name: 'CatalogUl',
  components: {
    CardPlaceholder,
    CatalogSelect,
    CatalogFilter,
    CardUl,
    CardCallUl,
  },
  data() {
    return {
      showFilters: false,
      rclasses: [],
      sorting: null,
      filtersCount: 0,
      limit: 8,
      loading: true,
      sortBy: 'lease_month_price',
    };
  },
  computed: {
    ...mapGetters({
      cars: 'cars/cars',
      fetchCars: 'cars/fetchCars',
      rentClasses: 'cars/classes',
      rclass: 'auth/rclass',
    }),
    cards() {
      const cards = this.cars ? this.cars : [];

      if (this.sorting === null || this.sorting === 'id') {
        return cards;
      }

      return cards.sort((a, b) => {
        if (this.sorting === 'price-asc') {
          return a[this.sortBy] - b[this.sortBy];
        } else if (this.sorting === 'price-desc') {
          return b[this.sortBy] - a[this.sortBy];
        }
      });
    },
  },
  created() {
    if (this.$route.query.rclass && this.$route.query.rclass.length) {
      this.$store.dispatch('auth/saveRclass', this.$route.query.rclass);
    }
  },
  methods: {
    openFilters() {
      disablePageScroll();
      this.showFilters = true;
    },
    closeFilters() {
      enablePageScroll();
      this.showFilters = false;
    },
    sortCards(type) {
      this.sorting = type;
    },
    showFiltersCount(val) {
      this.filtersCount = val;
    },
    loadCards() {
      const catalog = document.getElementById('catalog');

      const bottomOfCatalog = bottomOfElement(catalog);

      if (bottomOfCatalog) {
        if (this.limit <= this.cards.length) {
          this.limit = this.limit + 9;
        }
      }
    },
    getClass(id) {
      return this.rclasses.find((obj) => {
        return obj.id === id;
      });
    },
    setRclass() {
      if (this.rclass) {
        this.$store.dispatch('auth/saveRclass', '');
      }
    },
    getQuery() {
      const query = this.$route.query;
      const queryClass = Number(query.rclass);

      const result = {};

      if (queryClass) {
        result.rent_classes = [queryClass];
      }

      return result;
    },
  },
  mounted() {
    const query = this.getQuery();

    Promise.all([
      this.$store.dispatch('cars/fetchClasses'),
      this.$store.dispatch('cars/fetchCars', query),
      this.$store.dispatch('cars/fetchModels'),
    ])
      .then(() => {
        this.rclasses = this.rentClasses;

        if (!this.rclass) {
          return this.$store.dispatch('auth/fetchRclass');
        } else {
          return Promise.resolve();
        }
      })
      .then(() => {
        this.$store.dispatch('cars/setCars', this.fetchCars);

        this.setRclass();
        this.loading = false;
        window.addEventListener('scroll', this.loadCards, false);
      })
      .catch(error => {
        console.error('Error:', error);
        this.loading = false;
      });
  },
  beforeRouteLeave() {
    window.removeEventListener('scroll', this.loadCards, false);
  },
};
</script>
