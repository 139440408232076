<template>
  <div class="profile__step profile__step--3" :class="{'is-active': active, 'is-complete': completed}">
    <div class="profile__headline">
      <div class="profile__circle">
        <progress-circle :active="active" :progress="progress" :max-progress="4" :error="v$.$error" :completed="completed"></progress-circle>
      </div>
      <div>
        <div class="profile__title">Шаг 3
        </div>
        <div class="profile__text">Информация о месте жительства
        </div>
      </div>
    </div>
    <form class="profile__form" @submit.prevent="addressRequest">

      <fieldset class="profile__fieldset" :class="{ 'is-error': v$.params.region.$error, 'valid': !v$.params.region.$invalid  }">
        <legend class="profile__legend">Субъект РФ</legend>
        <div class="profile__error">Неверный формат названия</div>
        <v-select
            v-model="params.region"
            label="value"
            :filterable="false"
            :options="options.region"
            @search="(search, loading) => debounceRequest(search, loading, 'region')"
            placeholder="Ивановской области"
        >
          <template #selected-option-container="{ option }">
            <template v-if="option && Object.keys(option).length !== 0">
              <span class="vs__selected">{{ option.data.region }}</span>
            </template>
          </template>
          <template #no-options>
            Введите название субъекта
          </template>
        </v-select>
      </fieldset>

      <fieldset class="profile__fieldset" :class="{ 'profile__fieldset--inactive': !params.region, 'is-error': v$.params.city.$error, 'valid': !v$.params.city.$invalid }">
        <legend class="profile__legend">Город</legend>
          <v-select v-if="params.region"
              v-model="params.city"
              label="value"
              :filterable="false"
              :options="options.city"
              @search="(search, loading) => debounceRequest(search, loading, 'city')"
              placeholder="Иваново"
          >
          <template #no-options>
            Введите название города
          </template>
          <template #selected-option-container="{ option }">
            <template v-if="option && Object.keys(option).length !== 0">
              <span class="vs__selected">{{ option.data.city }}</span>
            </template>
          </template>
        </v-select>
        <div class="profile__error">Неверный формат названия</div>
      </fieldset>

      <fieldset class="profile__fieldset" :class="{ 'profile__fieldset--inactive': !params.city, 'is-error': v$.params.street.$error, 'valid': !v$.params.street.$invalid }">
        <legend class="profile__legend">Улица</legend>
        <div class="profile__error">Неверный формат названия</div>
        <v-select v-if="params.city"
            v-model="params.street"
            label="value"
            :filterable="false"
            :options="options.street"
            @search="(search, loading) => debounceRequest(search, loading, 'street')"
            @option:selected="clearOptions"
            placeholder="Им. Иванова"
        >
          <template #no-options>
            Введите название улицы
          </template>
          <template #selected-option-container="{ option }" >
            <template v-if="option && Object.keys(option).length !== 0">
              <span class="vs__selected">{{ option.data.street_with_type }}</span>
            </template>
          </template>
        </v-select>
      </fieldset>

      <fieldset class="profile__fieldset" :class="{ 'profile__fieldset--inactive': !params.street, 'is-error': v$.params.house.$error, 'valid': !v$.params.house.$invalid }">
        <legend class="profile__legend">Дом</legend>
        <input v-if="params.street" v-model="params.house" class="profile__input" type="text" name="place" placeholder="1"/>
        <div class="profile__error">Неверный формат названия</div>
      </fieldset>

      <fieldset class="profile__fieldset" :class="{ 'profile__fieldset--inactive': !params.house, 'valid': params.flat }">
        <legend class="profile__legend">Квартира</legend>
        <input v-if="params.house" v-model="params.flat" class="profile__input" type="text" name="place" placeholder="1"/>
        <div class="profile__error">Неверный формат названия</div>
      </fieldset>

      <div class="profile__actions">
        <div class="profile__button profile__button--3">
          <button class="button button--3" type="submit">Подтвердить
          </button>
        </div>
      </div>
    </form>
  </div >
</template>

<script>
import * as links from '@/store/links';
import vSelect from 'vue-select';
import {required} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import 'vue-select/dist/vue-select.css';
import ProgressCircle from '@/components/ProgressCircle';
import debounce from 'lodash/debounce';

export default {
  name: 'ScoringAddress',
  components: {
    vSelect,
    ProgressCircle
  },
  setup () {
    return {
      v$: useVuelidate()
    };
  },
  validations () {
    return {
      params: {
        region: {required},
        city: {required},
        street: {required},
        house: {required}
      },
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    completed: {}
  },
  data() {
    return {
      complete: false,
      options: {
        region: [],
        city: [],
        street: [],
      },
      params: {
        region: '',
        city: '',
        street: '',
        house: '',
        flat: ''
      },
      paramsArray: []

    };
  },
  computed: {
    progress() {
      return !this.v$.params.region.$invalid + !this.v$.params.city.$invalid + !this.v$.params.street.$invalid + !this.v$.params.house.$invalid;
    }
  },
  watch: {
    completed(val) {
      if (val) {
        this.complete = val;
      }
    }
  },
  methods: {
    debounceRequest: debounce(function (search, loading, type) {
      this.dataRequest(search, loading, type);
    }, 300),
    dataRequest(search, loading, type) {
      const searchLength = search.length > 2;

      if (searchLength) {
        loading(true);
        const params = this.getParams(type, search);

        this.axios.get(
          `${links.API}/suggest-address?${params}`,
          {
            loading: {message: 'Trying to login...'}
          }
        )
          .then(async(response) => {
            this.options[type] = response.data.suggestions;
            loading(false);
        })
        .catch((error) => {
          console.error(error);
          this.$store.dispatch('auth/checkAuth', error);
        });
      }
    },
    async addressRequest() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) return;

      const address = {
        area: this.params.region.data.region,
        city: this.params.city.data.city,
        street: this.params.street.data.street,
        house: this.params.house,
        apartment: this.params.flat
      };

      this.axios.post(
        `${links.API}/user/address`,
        address
      )
        .then(() => {
          this.$emit('readyStep');
        })
        .catch((error) => {
          console.error(error);
        });

      if (this.$metrika) {
        this.$metrika.reachGoal('lkscoring_click_3step_confirm');
      }

      this.complete = true;
    },
    getParams(type, search) {
      this.paramsArray = [];

      const amp = '&';
      const paramsNames = ['region', 'city', 'street'];
      let params = '';

      if (!this.params.region) {
        this.params.city = '';
      }

      if (!this.params.city) {
        this.params.street = '';
      }

      paramsNames.forEach((item) => {
        if (type === item) {
          this.paramsArray.push(`${type}=${search}`);
        } else {
          this.getParam(item);
        }
      });

      this.paramsArray.forEach((item, index) => {
        params = params + (index !== 0 ? amp : '') + item;
      });

      return params;
    },
    getParam(name) {
      const search = {
        region: 'region',
        city: 'city',
        street: 'street_with_type',
        house: 'house',
        flat: 'flat'
      };

      const param = this.params[name] ? `${name}=${this.params[name].data[search[name]]}` : '';
      if (param) {
        this.paramsArray.push(param);
      }
    },
    clearOptions(option) {
      if (!this.params.region) {
        this.params.region = option;
      }
      if (!this.params.city) {
        this.params.city = option;
      }
      if (!this.params.street) {
        this.params.street = option;
      }
    }
  }
};
</script>
