<template>
  <div class="landing-equipment">
    <aos-vue placement="top-bottom" animation="fade-up" :once="true" :duration="800">
      <div class="landing-equipment__content">
        <div class="landing-equipment__query">
          Что входит в&nbsp;стоимость подписки
        </div>
        <div class="landing-equipment__title">
          КРОМЕ НОВОГО автомобиля:
        </div>
      </div>
    </aos-vue>
    <aos-vue placement="top-bottom" animation="fade-up" :once="true" :duration="800">
      <Swiper class="landing-equipment__slider" :slidesPerView="slidesPerView" :spaceBetween="spaceBetween" :grid="grid" :breakpoints="breakpoints" :pagination="pagination" @swiper="setSwiper" :modules="modules">
        <SwiperSlide class="landing-equipment__slide" v-for="slide, index in slider" :key="index">
          <div class="landing-equipment__icon">
            <div class="landing-equipment__picture">
              <Picture :path="`icons/equipment-${index + 1}`" ext="png" name="Icon">
              </Picture>
            </div>
          </div>
          <div class="landing-equipment__inform">
            <div class="landing-equipment__subtitle" v-html="slide.subtitle">
            </div>
            <div class="landing-equipment__description" v-html="slide.description">
            </div>
            <div class="landing-equipment__note">
              <span class="landing-equipment__note-icon">
                <Picture path="icons/tick-circle" ext="png" name="Icon">
                </Picture>
              </span>
              <span class="landing-equipment__note-text">
                Включено в стоимость
              </span>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div class="landing-equipment__pagination"></div>
    </aos-vue>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from '@SwiperVue';
import { Pagination, Grid } from '@Swiper';
import Picture from './Picture';

export default {
  name: 'equipment',
  components: {
    Picture,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const equipmentSwiper = ref(null);

    const setSwiper = (swiper) => {
      equipmentSwiper.value = swiper;
    };
    
    return {
      enabled: true,
      slidesPerView: 1,
      spaceBetween: 50,
      grid: {
        fill: 'column',
        rows: 2,
      },
      breakpoints: {
        768: {
          enabled: false,
          slidesPerView: 1,
          spaceBetween: 0,
          grid: {
            fill: 'row',
            rows: 9,
          },
        },
        1025: {
          enabled: false,
          slidesPerView: 3,
          spaceBetween: 20,
          grid: {
            fill: 'row',
            rows: 3,
          },
        },
      },
      pagination: {
        el: '.landing-equipment__pagination',
        clickable: true,
        renderBullet: (index, className) => {
          return `<span class="${className} landing-equipment__bullet landing-equipment__bullet--${index + 1}">0${index + 1}</span>`;
        },
      },
      equipmentSwiper,
      setSwiper,
      modules: [Pagination, Grid],
    };
  },
  data() {
    return {
      slider: [
        {
          subtitle: 'КАСКО без франшизы',
          description: 'Автомобиль уже застрахован, чтобы было спокойнее',
        },
        {
          subtitle: 'Техническое обслуживание',
          description: 'Напомним о&nbsp;ТО и&nbsp;запишем на&nbsp;удобное время',
        },
        {
          subtitle: 'Хранение и&nbsp;замена шин',
          description: 'Запишем на&nbsp;шиномонтаж в&nbsp;удобное время и&nbsp;место или заменим резину сами',
        },
        {
          subtitle: 'Помощь на&nbsp;дороге и&nbsp;эвакуатор',
          description: 'Надеемся, что ничего не&nbsp;случится, но&nbsp;если что мы&nbsp;рядом',
        },
        {
          subtitle: 'Возможность выкупа',
          description: 'Если не&nbsp;захотите расставаться с&nbsp;авто, то&nbsp;его можно выкупить по&nbsp;фиксированной цене',
        },
        {
          subtitle: 'Удобное приложение',
          description: 'Для отслеживания автомобиля, пробега и&nbsp;оплаты штрафов ГИБДД',
        },
        {
          subtitle: 'Лояльность и&nbsp;поддержка',
          description: 'Живая поддержка',
        },
        {
          subtitle: 'Оплата налогов',
          description: 'Налоги уже оплачены, об&nbsp;этом не&nbsp;нужно думать',
        },
        {
          subtitle: 'Постановка на&nbsp;учет в&nbsp;ГИБДД',
          description: 'И&nbsp;об&nbsp;этом мы&nbsp;уже побеспокоились',
        },
      ],
    };
  }
};
</script>