<template>
  <Header></Header>
  <main class="home" role="main">
    <div class="error404">
      <picture>
        <source srcset="@/assets/images/404.webp" type="image/webp"/>
        <img class="error404__img" src="@/assets/images/404.png" alt="404"/>
      </picture>
      <h1 class="error404__title">Упс, кажется, что-то пошло не так</h1>
      <div class="error404__button">
        <router-link class="button" to="/">На главную</router-link>
      </div>
    </div>
  </main>
</template>

<script>
import Header from '@/layouts/Header';
export default {
  name: 'Error404',
  components: {
    Header
  }
};
</script>