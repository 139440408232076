<template>
  <a v-if="href" :href="href" class="landing-button" :class="[modifier ? `landing-button--${modifier}` : '']">
    {{ name }}
  </a>
  <button v-else class="landing-button" :class="[modifier ? `landing-button--${modifier}` : '']" :type="type">
    {{ name }}
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    name: {
      type: String,
      required: true,
    },
    modifier: {
      type: String,
    },
    href: {
      type: String,
    },
    type: {
      type: String,
      default: 'button'
    }
  },
};
</script>