<template>
  <button @click="swiper.slideNext()" class="card__next">
    <svg width="6" height="12" viewBox="0 0 6 12" fill="none">
      <path d="M0 0L6 6L0 12V6V0Z" fill="black"></path>
    </svg>
  </button>
</template>
<script>

export default {
  props: {
    swiper: {
      type: Object
    }
  },
};
</script>