<template>
  <main class="home" role="main">
    <section class="home__section home__section--profile" id="profile">
      <div class="profile">
        <div class="profile__head">
          <div class="profile__title">Добро пожаловать в&nbsp;Личный Кабинет</div>
        </div>
        <div v-if="loaded" class="profile__content">
          <div class="profile__intro">
            <div class="profile__image is-active">
              <picture>
                <img v-if="loaded" :src="car.photos[0]" alt="image" title="" />
              </picture>
              <a href="#" class="profile__in-map" @click.prevent="showMap = true">На карте</a>
            </div>
            <div class="profile__information">
              <div class="profile__subtitle">{{ car.make }} {{ car.version }}</div>
              <div v-if="false" class="profile__downloads">
                <a :href="docs.act" class="profile__download">Договор</a>
                <a :href="docs.proxy" class="profile__download">Доверенность</a>
                <a :href="docs.insurance" class="profile__download">Страховка</a>
              </div>
            </div>
            <div class="profile__map" :class="{ 'is-active': showMap }">
              <yandex-map v-if="loading.map" :coords="[car.latitude, car.longitude]" :settings="mapSettings" :controls="[]">
                <ymap-marker :marker-id="'car-point'" :coords="[car.latitude, car.longitude]" :balloon="balloon"></ymap-marker>
              </yandex-map>
              <div class="profile__in-image" @click="showMap = false">Вернуться</div>
            </div>
          </div>
          <div class="profile__cards">
            <div class="profile__card">
              <div class="profile__subtitle">Всего осталось КМ</div>
              <div class="profile__indication">{{ mileage }}<span>/год</span></div>
              <div class="profile__scale">
                <span :style="{ width: mileagePercent + '%' }"></span>
              </div>
            </div>
            <div class="profile__card">
              <div class="profile__subtitle">До конца подписки</div>
              <div class="profile__indication">{{ mileageDays }} дней</div>
              <div class="profile__scale">
                <span :style="{ width: mileageDaysPercent + '%' }"></span>
              </div>
            </div>
          </div>
          <div class="profile__title">История платежей</div>
          <div class="profile__table" v-if="payments">
            <Table :items="payments"></Table>
          </div>

          <div class="profile__title">Персональная информация</div>
          <form class="profile__driver profile__driver--1">
            <div class="profile__inputs profile__inputs--1">
              <fieldset class="profile__fieldset" disabled>
                <legend class="profile__legend">ФИО</legend>
                <input class="profile__input" type="text" name="name" placeholder="Иван Иванович Иванов"
                  :value="user.name" />
              </fieldset>
              <fieldset class="profile__fieldset" disabled>
                <legend class="profile__legend">E-mail</legend>
                <input class="profile__input" type="email" name="email" placeholder="name@email.ru" :value="user.email" />
                <div class="profile__error">Неверный формат электронной почты</div>
              </fieldset>
              <fieldset class="profile__fieldset" disabled>
                <legend class="profile__legend">Телефон</legend>
                <input class="profile__input" type="tel" name="tel" placeholder="+7 ***" :value="user.phone" />
                <div class="profile__error">Неверный формат телефона</div>
              </fieldset>
            </div>
          </form>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import * as links from '../store/links';
import Table from '../components/write-off-table/Table';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';

export default {
  name: 'profile',
  components: { yandexMap, ymapMarker, Table },
  data() {
    return {
      user: {},
      mapSettings: {
        apiKey: this.API_KEY,
        zoom: 7
      },
      API_KEY: '0f08cdd4-c2ee-45f6-9ef0-b0d49332bb19',
      balloon: {
        body: 'Ваш автомобиль'
      },
      loading: {
        map: false
      },
      car: {},
      meta: {},
      docs: {},
      rclass: {},
      links: {},
      loaded: false,
      carDefault: {
        photos: [
          require('@/assets/images/catalog/car-placeholder.png'),
        ],
      },
      maxMileage: 30000,
      fakeMileageFactor: 28900,
      bookingDate: new Date(2021, 0, 1),
      daysTotal: 0,
      daysToEnd: 0,
      showMap: false,
      payments: []
    };
  },
  computed: {
    mileage() {
      return this.maxMileage - this.carMileage;
    },
    mileagePercent() {
      return (this.mileage / this.maxMileage) * 100;
    },
    mileagePerMonth() {
      return this.maxMileage / 12;
    },
    mileageMonth() {
      //TODO добавить поддержку високосных годов
      const pastMonths = Math.ceil((new Date().getTime() - this.bookingDate.getTime()) / 2629800000);
      const currentMonth = pastMonths + 1;
      const monthsLeft = 12 - (currentMonth - 1);
      const mileagePerMonthLeft = this.mileage - ((monthsLeft - 1) * this.mileagePerMonth);
      return mileagePerMonthLeft > this.mileagePerMonth ? this.mileagePerMonth : mileagePerMonthLeft;
    },
    mileageMonthPercent() {
      return (this.mileageMonth / this.mileagePerMonth) * 100;
    },
    mileageDays() {
      return this.daysToEnd;
    },
    mileageDaysPercent() {
      return (this.daysToEnd / this.daysTotal) * 100;
    },
  },
  mounted() {
    this.profileRequest();
  },
  methods: {
    async deleteProfile() {
      const deleteConfirmation = confirm('Удалить профиль?');

      if (deleteConfirmation) {
        this.axios.delete(
          `${links.API}/user`,
          {
            loading: { message: 'Trying to login...' }
          }
        )
          .then(async () => {
            this.$router.push('/registration');
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    async profileRequest() {
      this.axios.get(
        `${links.API}/user`,
        {
          loading: { message: 'Trying to login...' }
        }
      )
        .then(async (response) => {
          this.user = response.data;
          this.bookingDate = new Date(this.user.booked_at);
          this.docs.act = this.user.documents.act;
          this.docs.proxy = this.user.documents.proxy;
          this.docs.insurance = this.user.documents.insurance;
          this.daysToEnd = this.user.days.remain;
          this.daysTotal = this.user.days.total;

          this.carRequest();
          this.paymentsRequest();
        })
        .catch((error) => {
          console.error(error);
          this.$store.dispatch('auth/checkAuth', error);
        });
    },
    async carRequest() {
      this.axios.get(
        `${links.API}/cars/${this.user.car_id}`,
        {
          loading: { message: 'Trying to login...' }
        }
      )
        .then(async (response) => {
          this.car = response.data;

          this.carMileage = this.car.mileage;

          if (this.car.latitude && this.car.longitude) {
            this.loading.map = true;
            this.mapSettings = {
              apiKey: this.API_KEY,
              coords: [this.car.latitude, this.car.longitude],
              zoom: 7
            };
          }

          this.setDefault();
        })
        .catch((error) => {
          console.error(error);
          this.$store.dispatch('auth/checkAuth', error);
        });
    },
    async paymentsRequest() {
      this.axios.get(
        `${links.API}/user/payments`,
        {
          loading: { message: 'Trying to login...' }
        }
      )
        .then(async (response) => {
          this.payments = response.data;
          this.payments.sort((a, b) => {
            const nameA = a.payment_date.toLowerCase();
            const nameB = b.payment_date.toLowerCase();

            if (nameA < nameB)
              return -1;
            if (nameA > nameB)
              return 1;

            return 0;
          });
        })
        .catch((error) => {
          console.error(error);
          this.$store.dispatch('auth/checkAuth', error);
        });
    },
    async rentClassRequest() {
      this.axios.get(
        `${links.API}/rent-classes`,
        {
          loading: { message: 'Trying to login...' }
        }
      )
        .then(async (response) => {
          this.rclass = response.data.find(rClassEl => rClassEl.id === this.car.rent_class_id);

          if (this.rclass && this.rclass.photos.length) {
            this.car.photos = this.rclass.photos;
          } else {
            this.car.photos = this.carDefault.photos;
          }

          this.loaded = true;
        })
        .catch((error) => {
          console.error(error);
          this.$store.dispatch('auth/checkAuth', error);
        });
    },
    setDefault() {
      if (!this.car.photos.length) {
        this.rentClassRequest();
      } else {
        this.loaded = true;
      }
    }
  }
};
</script>

<style scoped>
.ymap-container {
  height: 100%;
}
</style>