<template>
  <div class="profile__step profile__step--5" :class="{'is-active': active, 'is-complete': completed}">
    <div class="profile__headline">
      <div class="profile__circle">
        <progress-circle :active="active" :progress="1" :max-progress="2" :error="false" :completed="completed"></progress-circle>
      </div>
      <div>
        <div class="profile__title">Шаг 5
        </div>
        <div class="profile__text">Привязать карту
        </div>
      </div>
    </div>
    <form class="profile__form" @submit.prevent="cardRequest">
      <div class="profile__text">Привяжите карту, чтобы пройти скоринг и&nbsp;в&nbsp;будущем иметь возможность оплачивать подписку
      </div>
      <div class="profile__button profile__button--4">
        <button class="button button--4" type="submit" :disabled="loading">Верифицировать карту</button>
      </div>
    </form>
  </div>
</template>

<script>
import * as links from '../../store/links';
import ProgressCircle from '@/components/ProgressCircle';

export default {
  name: 'ScoringCard',
  components: {
    ProgressCircle
  },
  data() {
    return {
      complete: false,
      loading: false
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    completed: {}
  },
  watch: {
    completed(val) {
      if (val) {
        this.complete = val;
      }
    }
  },
  methods: {
    cardRequest() {
      this.loading = true;
      if (this.$metrika) {
        this.$metrika.reachGoal('lkscoring_click_5step_confirm');

      }
      this.axios.get(
          `${links.API}/user/booking/card`,
          {
            loading: {message: 'Trying to login...'}
          }
      )
          .then(async(response) => {
            this.loading = false;
            window.location.href = response.data.verification_url;
          })
          .catch((error) => {
            console.error(error);
            this.$store.dispatch('auth/checkAuth', error);
          });
    }
  }
};
</script>