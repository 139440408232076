<template>
  <div class="card">
    <div class="card__slider">
      <div class="card__slide">
        <picture>
          <img src="@/assets/images/catalog/call.jpg" alt="Car" title=""/>
        </picture>
      </div>
    </div>
    <div class="card__content">
      <div class="card__title">А еще что-нибудь есть?
      </div>
      <div class="card__text">Подберите автомобиль и комплектацию, которые подходят именно вам</div>
      <div class="card__button">
        <button class="button" type="button" @click.prevent="callBackDialog">Заказать звонок</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardCall',
  methods: {
    callBackDialog() {
      this.$store.dispatch('call/show', true);
      if (this.$metrika) {
        this.$metrika.reachGoal('lk_callback_request');
      }
    }
  },
};
</script>