<template>
  <div class="table__wrapper">
    <table class="table">
      <thead>
        <th>Тип списания</th>
        <th>Дата списания</th>
        <th>Сумма списания</th>
        <th>Статус</th>
        <!-- <th>Ссылка на оплату</th> -->
      </thead>
      <tbody>
        <raw v-for="(raw, index) in items" :raw="raw" :key="index"></raw>
      </tbody>
    </table>
  </div>
</template>

<script>
import Raw from './Raw';

export default {
  name: 'Table',
  props: {
    items: Object,
  },
  components: {
    Raw,
  },
};
</script>
