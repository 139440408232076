<template>
  <div class="profile__step profile__step--4" :class="{'is-active': active, 'is-complete': completed}">
    <div class="profile__headline">
      <div class="profile__circle">
        <progress-circle :active="active" :progress="progress" :max-progress="8" :error="v$.$error" :completed="completed"></progress-circle>
      </div>
      <div>
        <div class="profile__title">Шаг 4</div>
        <div class="profile__text">Добавление второго водителя</div>
      </div>
    </div>
    <form class="profile__form" @submit.prevent="contactsRequest">
      <fieldset class="profile__fieldset" :class="{ 'is-error': v$.lastName.$error, 'valid': !v$.lastName.$invalid }">
        <legend class="profile__legend">Фамилия</legend>
        <input v-model="lastName" class="profile__input" type="text" name="name" placeholder="Иванов"/>
        <div class="profile__error">Неверный формат фамилии</div>
      </fieldset>
      <fieldset class="profile__fieldset" :class="{ 'is-error': v$.firstName.$error, 'valid': !v$.firstName.$invalid }">
        <legend class="profile__legend">Имя</legend>
        <input v-model="firstName" class="profile__input" type="text" name="name" placeholder="Иван"/>
        <div class="profile__error">Неверный формат имени</div>
      </fieldset>
      <fieldset class="profile__fieldset" :class="{ 'is-error': v$.middleName.$error, 'valid': !v$.middleName.$invalid }">
        <legend class="profile__legend">Отчество</legend>
        <input v-model="middleName" class="profile__input" type="text" name="name" placeholder="Иванович"/>
        <div class="profile__error">Неверный формат отчества</div>
      </fieldset>
      <fieldset class="profile__fieldset" :class="{ 'is-error': v$.birthDate.$error, 'valid': !v$.birthDate.$invalid }">
        <legend class="profile__legend">Дата рождения</legend>
        <input v-model="birthDate" class="profile__input" name="name" v-maska="'##.##.####'" placeholder="дд.мм.гггг"/>
        <div class="profile__error">Неверный формат даты</div>
      </fieldset>
      <fieldset class="profile__fieldset" :class="{ 'is-error': v$.driverLicenseNum.$error, 'valid': !v$.driverLicenseNum.$invalid }">
        <legend class="profile__legend">Номер и серия ВУ</legend>
        <input v-model="driverLicenseNum" class="profile__input" type="text" name="name" placeholder="0000 000000"/>
        <div class="profile__error">Неверный номер</div>
      </fieldset>
      <fieldset class="profile__fieldset" :class="{ 'is-error': v$.driverLicenseDate.$error, 'valid': !v$.driverLicenseDate.$invalid }">
        <legend class="profile__legend">Дата выдачи ВУ</legend>
        <input v-model="driverLicenseDate" class="profile__input" v-maska="'##.##.####'" name="name" placeholder="дд.мм.гггг"/>
        <div class="profile__error">Неверная дата ВУ</div>
      </fieldset>
      <fieldset class="profile__fieldset" :class="{ 'is-error': v$.phone.$error, 'valid': !v$.phone.$invalid }">
        <legend class="profile__legend">Телефон</legend>
        <input v-model="phone" class="profile__input" type="tel" name="phone" placeholder="+7 ***"/>
        <div class="profile__error">Неверный формат телефона</div>
      </fieldset>
      <fieldset class="profile__fieldset" :class="{ 'is-error': v$.email.$error, 'valid': !v$.email.$invalid }">
        <legend class="profile__legend">E-mail</legend>
        <input v-model="email" class="profile__input" type="email" name="email" placeholder="name@email.ru"/>
        <div class="profile__error">Неверный формат электронной почты</div>
      </fieldset>
      <div class="profile__buttons">
        <div class="profile__button">
          <button class="button button--save" type="submit">Подтвердить</button>
        </div>
        <div class="profile__button">
          <button class="button button--add" type="button" @click="nextStep">Пропустить</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import ProgressCircle from '@/components/ProgressCircle';
import * as links from '@/store/links';

export default {
  name: 'ScoringContacts',
  components: {
    ProgressCircle
  },
  setup () {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      complete: false,
      firstName: '',
      lastName: '',
      middleName: '',
      birthDate: '',
      driverLicenseNum: '',
      driverLicenseDate: '',
      email: '',
      phone: '',
    };
  },
  computed: {
    progress() {
      return !this.v$.lastName.$invalid + !this.v$.firstName.$invalid + !this.v$.middleName.$invalid + !this.v$.birthDate.$invalid + !this.v$.driverLicenseNum.$invalid + !this.v$.driverLicenseDate.$invalid + !this.v$.email.$invalid + !this.v$.phone.$invalid;
    }
  },
  validations () {
    return {
      firstName: {required},
      lastName: {required},
      middleName: {required},
      birthDate: {required},
      driverLicenseNum: {required},
      driverLicenseDate: {required},
      email: {required, email},
      phone: {required},
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    completed: {}
  },
  watch: {
    completed(val) {
      if (val) {
        this.complete = val;
      }
    }
  },
  methods: {
    async contactsRequest() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) return;

      if (this.$metrika) {
        this.$metrika.reachGoal('lkscoring_click_4step_confirm');

      }

      this.axios.post(
        `${links.API}/user/second-driver`,
        {
          first_name: this.firstName,
          last_name: this.lastName,
          middle_name: this.middleName,
          birth_date: this.birthDate,
          driver_license_num: this.driverLicenseNum,
          driver_license_date: this.driverLicenseDate,
          email: this.email,
          phone: this.phone,
        }
      )
        .then(() => {
          this.$emit('readyStep');
        })
        .catch((error) => {
          console.error(error);
        });

      this.complete = true;
    },
    nextStep() {
      if (this.$metrika) {
        this.$metrika.reachGoal('lkscoring_click_4step_confirm_v2');
      }
      this.$emit('readyStep');
      this.complete = true;
    }
  }
};
</script>
