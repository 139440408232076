export default {
  Landing: {
    name: 'Автоподписка - выгодный способ владеть авто без забот',
    description: 'Автоподписка - это сервис подписки на автомобили. Подпишитесь на автоподписку прямо сейчас и получите свой автомобиль в удобное для вас время без лишних забот!',
    keywords: 'автоподписка, подписка на автомобили, автомобильная подписка'
  },
  LandingUl: {
    name: 'Автоподписка для юридических лиц',
    description: 'Автоподписка для юридических лиц - это удобный сервис подписки на автомобили для компаний. Оформите автоподписку прямо сейчас и получайте свои автомобили в удобное для вас время без лишних забот! ',
    keywords: 'автоподписка для юридических лиц, подписка на автомобили для компаний, корпоративная автоподписка, лизинг, авто для юрлиц, авто в лизинг'
  },
  CatalogUl: {
    name: 'Каталог автомобилей для юридических лиц',
    description: 'Здесь вы можете найти все автомобили в наличии для юридических лиц. Все авто в наличии и доступны к получению в течение недели',
    keywords: 'автомобили для юр.лиц, автоподписка для юридических лиц, лизинг для компаний, автолизинг, автомобили для юрлиц'
  },
  CarUl: {
    name: 'АВТОDОМ.Подписка для юр. лиц — автомобиль',
    description: 'Здесь вы можете найти информацию об автомобиле и выбрать подходящие для вас условия для подписки',
    keywords: 'автоподписка, автодом подписка, подписка на авто'
  },
  Catalog: {
    name: 'Каталог автомобилей доступных по подписке',
    description: 'В каталоге впредставлены только автомобили в наличии. Выбирай и владей авто в премиальной комплектации без забот. КАСКО, ОСАГО, ТО включены.',
    keywords: 'автоподписка каталог, автоподписка, автомобили по подписке, Geely по подписке, Chery по подписке, AMODA по подписке, HAVAL по подписке'
  },
  Car: {
    name: 'АВТОDОМ.Подписка — автомобиль',
    description: 'Описание Car',
    keywords: 'автоподписка, авто по подписке, Geely по подписке, Chery по подписке, AMODA по подписке, HAVAL по подписке'
  },
  Login: {
    name: 'Автоподписка — Логин',
    description: 'Войдите в личный кабинет для управления своей подпиской',
    keywords: 'автодом подписка, вход автоподписка'
  },
  Registration: {
    name: 'Автоподписка — Регистрация',
    description: 'Зарегистрируйтесь, чтобы иметь возможность взять автомобиль по подписке',
    keywords: 'автодом подписка, регистрация автоподписка'
  },
  Profile: {
    name: 'Автоподписка — Личный кабинет',
    description: 'Описание Profile',
    keywords: 'Ключевые слова Profile'
  },
  Scoring: {
    name: 'Автоподписка — Скоринг',
    description: 'Описание Scoring',
    keywords: 'Ключевые слова Scoring'
  },
};