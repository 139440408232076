<template>
  <transition name="fade">
    <div v-if="isShow" class="landing-popup">
      <div class="landing-popup__overlay"></div>
      <div class="landing-popup__container"
        :class="{ 'thanks': type === 'thanks', 'information': type === 'information', 'error-requisition': type === 'errorRequisition' }"
        ref="popupBody">
        <button class="landing-popup__close" type="button" @click="closePopup()">
          <svg>
            <use xlink:href="@/assets/images/sprites.svg#icon-close"></use>
          </svg>
        </button>
        <div class="landing-popup__content">

          <template v-if="type === 'call'">
            <div class="landing-popup__title">
              Заказать звонок
            </div>
            <div class="landing-popup__text">
              Чтобы получить подробную информацию об автомобилях в наличии и комплектации
            </div>
            <form class="landing-popup__form" @submit.prevent="formSend">
              <input class="landing-popup__input" :class="{ 'is-error': v$.name.$error }" v-model="name" type="text"
                name="name" placeholder="Имя" />
              <input class="landing-popup__input" :class="{ 'is-error': v$.phone.$error }" v-model="phone" type="tel"
                name="phone" placeholder="Телефон" />
              <div class="landing-popup__note">
                <span class="landing-popup__span">Нажав на кнопку, соглашаюсь на обработку&nbsp;</span>
                <a target="_blank" href="https://api.autopodpiska.ru/document/personal-data-consent" class="landing-popup__link">персональных данных</a>
              </div>
              <div class="landing-popup__button">
                <Button type="submit" name="Заказать звонок"></Button>
              </div>
            </form>
          </template>

          <template v-if="type === 'callJur'">
            <div class="landing-popup__title">
              Оставьте заявку
            </div>
            <div class="landing-popup__text">
              Мы обязательно с Вами свяжемся
            </div>
            <form class="landing-popup__form" @submit.prevent="formSend">
              <input class="landing-popup__input" :class="{ 'is-error': v$.name.$error }" v-model="name" type="text"
                name="name" placeholder="Имя" />
              <input class="landing-popup__input" :class="{ 'is-error': v$.email.$error }" v-model="email" type="text"
                name="email" placeholder="E-mail" />
              <input class="landing-popup__input" :class="{ 'is-error': v$.phone.$error }" v-model="phone" type="tel"
                name="phone" placeholder="Телефон" />
              <input class="landing-popup__input" :class="{ 'is-error': v$.inn.$error }" v-model="inn" type="text"
                name="inn" placeholder="ИНН" />
              <div class="landing-popup__note">
                <span class="landing-popup__span">Нажав на кнопку, соглашаюсь на обработку&nbsp;</span>
                <a target="_blank" href="https://api.autopodpiska.ru/document/personal-data-consent" class="landing-popup__link">персональных данных</a>
              </div>
              <div class="landing-popup__button">
                <Button type="submit" name="Оставить заявку"></Button>
              </div>
            </form>
          </template>

          <template v-if="type === 'thanks'">
            <div class="landing-popup__icon">
              <svg>
                <use xlink:href="@/assets/images/sprites.svg#icon-check"></use>
              </svg>
            </div>
            <div class="landing-popup__title">
              Ваше сообщение успешно отправлено!
            </div>
            <div class="landing-popup__text">
              Наш менеджер свяжется с&nbsp;вами
            </div>
            <div class="landing-popup__button">
              <Button @click="closePopup()" name="Спасибо!"></Button>
            </div>
          </template>

          <template v-if="type === 'errorRequisition'">
            <div class="landing-popup__icon">
              <svg>
                <use xlink:href="@/assets/images/sprites.svg#icon-error"></use>
              </svg>
            </div>
            <div class="landing-popup__title">
              Упс, что-то пошло не так.
            </div>
            <div class="landing-popup__text">
              Пожалуйста, позвоните нам: 8 (499) 270-07-60
            </div>
            <div class="landing-popup__button">
              <Button @click="closePopup()" name="Закрыть"></Button>
            </div>
          </template>

          <template v-if="type === 'information'">
            <div class="landing-popup__content">
              <div class="landing-popup__title">
                Как работает сервис
              </div>
              <div class="landing-popup__video">
                <YoutubeIframe width="100%" height="100%" video-id="A-IFJLKcsww" />
              </div>
            </div>

          </template>
        </div>
      </div>

    </div>
  </transition>
</template>

<script>
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { YoutubeIframe } from '@vue-youtube/component';
import useVuelidate from '@vuelidate/core';
import { required, email, requiredIf } from '@vuelidate/validators';
import Button from './Button';
import * as links from '@/store/links';

const checkPhone = (val) => (/^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/).test(val);

export default {
  name: 'Popup',
  components: {
    Button,
    YoutubeIframe
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    isShow: {
      type: Boolean,
    }
  },
  emits: [
    'changePopupState',
  ],
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  validations() {
    return {
      phone: {
        required, checkPhone
      },
      name: { required: requiredIf(() => this.type === 'callJur') },
      inn: { required: requiredIf(() => this.type === 'callJur') },
      email: { email, required: requiredIf(() => this.type === 'callJur') }
    };
  },
  watch: {
    isShow(val) {
      if (val) {
        disablePageScroll();
      } else {
        enablePageScroll();
      }
    }
  },
  methods: {
    async formSend() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) return;

      if (this.$metrika) {
        this.$metrika.reachGoal('main_getanoffer_fs');
      }

      const data = {
        phone: this.phone,
        name: this.name ? this.name : this.phone,
        url: window.location.href
      };

      const postLink = this.type === 'callJur' ? 'back-call/legal-entity' : 'back-call/private-person';

      if (this.type === 'callJur') {
        data['inn'] = this.inn;
        data['email'] = this.email;
      }

      this.axios.post(
        `${links.API}/${postLink}`,
        {
          ...data
        },
      )
        .then(async () => {
          this.closePopup('thanks');
        })
        .catch((error) => {
          console.error(error);
          this.closePopup('errorRequisition');
        });
    },
    dataReset() {
      this.phone = '';
      this.name = '';
      this.inn = '';
      this.email = '';
    },
    closePopup(type) {
      this.v$.$reset();
      this.$emit('changePopupState');
      this.dataReset();

      if (type) {
        setTimeout(() => this.$emit('changePopupState', type), 300);
      }
    }
  },
  data() {
    return {
      phone: '',
      name: '',
      email: '',
      inn: '',
      url: '',

    };
  }
};
</script>