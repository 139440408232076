import axios from 'axios';
import * as links from '../../store/links';
import * as types from '../mutation-types';

const namespaced = true;

// state
const state = {
  cars: [],
  fetchCars: [],
  models: [],
  counts: {},
  classes: [],
  chosenCar: ''
};

// getters
const getters = {
  cars: state => state.cars,
  fetchCars: state => state.fetchCars,
  models: state => state.models,
  counts: state => state.counts,
  classes: state => state.classes,
  chosenCar: state => state.chosenCar,
};

// mutations
const mutations = {
  [types.FETCH_CARS_SUCCESS](state, payload) {
    state.fetchCars = payload;
  },
  [types.FETCH_COUNTS_SUCCESS](state, payload) {
    state.counts = payload;
  },
  [types.FETCH_MODELS_SUCCESS](state, payload) {
    state.models = payload;
  },
  [types.FETCH_CLASSES_SUCCESS](state, payload) {
    state.classes = payload;
  },
  [types.SET_CHOSEN_CAR](state, payload) {
    state.chosenCar = payload;
  },
  [types.SET_CARS](state, payload) {
    state.cars = payload;
  },
  [types.FETCH_FILTER_SUCCESS](state, payload) {
    state.filters = payload;
  },
};

//actions
const actions = {
  async fetchCars({commit}, payload = {}) {
    try {
      const {data} = await axios.post(`${links.API}/cars`, payload, {loading: {message: 'Loading: Cars...'}});

      const cars = data.cars.filter((item) => item.make !== null );
      const counts = data.counts;

      commit(types.FETCH_CARS_SUCCESS, cars);
      commit(types.FETCH_COUNTS_SUCCESS, counts);
    } catch (e) {
      console.error(e);
    }
  },
  setCars({commit}, payload) {
    commit(types.SET_CARS, payload);
  },
  async fetchModels({commit}) {
    try {
      const {data} = await axios.get(`${links.API}/models`, {loading: {message: 'Loading: Cars...'}});

      commit(types.FETCH_MODELS_SUCCESS, data);
    } catch (e) {
      console.error(e);
    }
  },
  async fetchClasses({commit}) {
    try {
      const {data} = await axios.get(`${links.API}/rent-classes`, {loading: {message: 'Loading: Classes...'}});
      commit(types.FETCH_CLASSES_SUCCESS, data);
    } catch (e) {
      console.error(e);
    }
  },
  setChosenCar({commit}, payload) {
    if (payload) {
      localStorage.setItem('cars.chosenCar', payload);
    } else {
      localStorage.removeItem('cars.chosenCar');
    }
    commit(types.SET_CHOSEN_CAR, payload);
  },
  async fetchChosenCar({commit}) {
    let chosenCar = localStorage.getItem('cars.chosenCar');
    if (chosenCar) {
      commit(types.SET_CHOSEN_CAR, chosenCar);
      return true;
    }
    return false;
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
