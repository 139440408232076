<template>
  <div class="card">
    <div class="card__slider">
      <div class="card__slide">
        <picture>
          <img src="@/assets/images/catalog/call.jpg" alt="Car" title=""/>
        </picture>
      </div>
    </div>
    <div class="card__content">
      <div class="card__title">А еще что-нибудь есть?
      </div>
      <div class="card__text">Подберите автомобиль и комплектацию, которые подходят именно вам</div>
      <div class="card__button">
        <button class="button" type="button" @click.prevent="callUlShow">Заказать звонок</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardCallUl',
  methods: {
    callUlShow() {
      this.$store.dispatch('callUl/show', {show: true, type: 'callJur', carId: 0});
    },
  },
};
</script>