<template>
  <div class="landing-catalog">
    <aos-vue placement="top-bottom" animation="fade-up" :duration="800" :once="true">
      <div class="landing-catalog__headtitle">
        Что в наличии
      </div>
      <div class="landing-catalog__title">
        Доступные модели
      </div>
    </aos-vue>
    <aos-vue placement="top-bottom" animation="fade-up" :duration="800" :once="true">
      <div class="landing-catalog__content">
        <div class="landing-catalog__cars">
          <div class="landing-catalog__border">
            <div class="landing-catalog__border-top"></div>
          </div>
          <template v-if="cars.length">
            <template v-for="car in cars" :key="car.id">
              <div class="landing-catalog__line" @mouseleave="setFirstSlide">
                <div class="landing-catalog__inset">
                  <div class="landing-catalog__detail">
                    <div class="landing-catalog__model">
                      {{ car.model }}
                    </div>
                    <div class="landing-catalog__price">
                      <span>{{ Math.ceil(car.month_price * 1.1 / 100) * 100 }}</span>
                      р. ежемесячно
                    </div>
                    <div class="landing-catalog__info">
                      <div class="landing-catalog__subtitle">
                        Включены в стоимость:
                      </div>
                      <div class="landing-catalog__services">
                        <div v-for="service, index in car.service" :key="index" class="landing-catalog__service">
                          <svg v-if="service === 1">
                            <use xlink:href="@/assets/images/sprites.svg#icon-service-1"></use>
                          </svg>
                          <svg v-if="service === 2">
                            <use xlink:href="@/assets/images/sprites.svg#icon-service-2"></use>
                          </svg>
                          <svg v-if="service === 3">
                            <use xlink:href="@/assets/images/sprites.svg#icon-service-3"></use>
                          </svg>
                          <svg v-if="service === 4">
                            <use xlink:href="@/assets/images/sprites.svg#icon-service-4"></use>
                          </svg>
                          <svg v-if="service === 5">
                            <use xlink:href="@/assets/images/sprites.svg#icon-service-5"></use>
                          </svg>
                          <svg v-if="service === 6">
                            <use xlink:href="@/assets/images/sprites.svg#icon-service-1"></use>
                          </svg>
                          <svg v-if="service === 7">
                            <use xlink:href="@/assets/images/sprites.svg#icon-service-7"></use>
                          </svg>
                          <span class="landing-catalog__tooltip" v-html="showService(service)">
                          </span>
                        </div>
                      </div>
                      <div class="landing-catalog__description">
                        Комплектация:
                      </div>
                      <div class="landing-catalog__conditions">
                        <div v-for="condition, index in car.conditions" :key="index" class="landing-catalog__condition">
                          <div class="landing-catalog__icon icon_desktop">
                            <svg>
                              <use xlink:href="@/assets/images/sprites.svg#icon-list-condition"></use>
                            </svg>
                          </div>
                          <div class="landing-catalog__icon icon_mobile">
                            <svg>
                              <use xlink:href="@/assets/images/sprites.svg#icon-list-condition-mobile"></use>
                            </svg>
                          </div>
                          {{ condition }}
                        </div>
                      </div>
                      <div class="landing-catalog__button">
                        <Button @click="pushToCatalog(car.id)" name="В каталог"></Button>
                      </div>
                    </div>
                  </div>
                  <div class="landing-catalog__images">
                    <Swiper loop :effect="'fade'" :spaceBetween="0" :initialSlide="4" class="landing-catalog__slider"
                      :modules="modules" :thumbs="{ swiper: `.thumbs-${car.name}` }">
                      <SwiperSlide v-for="_, index in Array(4)" :key="index" class="landing-catalog__slide">
                        <Picture device :path="`cars/${car.brand}_${car.name}/${index + 1}`" ext="jpg" :name="car.model">
                        </Picture>
                      </SwiperSlide>
                      <SwiperSlide class="landing-catalog__slide first-slide">
                        <Picture device :path="`cars/${car.brand}_${car.name}/5`" ext="png" :name="car.model">
                        </Picture>
                      </SwiperSlide>
                    </Swiper>
                    <Swiper class="landing-catalog__thumbs" :class="`thumbs-${car.name}`" :space-between="0"
                      :allow-touch-move="false" watch-slides-progress :modules="modules" slides-per-view="auto">
                      <SwiperSlide v-for="_, index in Array(4)" :key="index" class="landing-catalog__slide">
                        <Picture device :path="`cars/${car.brand}_${car.name}/${index + 1}`" ext="jpg" :name="car.model">
                        </Picture>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                  <div class="landing-catalog__circle"></div>
                  <div class="landing-catalog__element"></div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </aos-vue>
  </div>
</template>

<script>
import { Thumbs, EffectFade } from '@Swiper';
import { Swiper, SwiperSlide } from '@SwiperVue';
import * as links from '@/store/links';
import defaultCars from '@/const/default-cars';
import Picture from './Picture';
import Button from './Button';

export default {
  name: 'Catalog',
  components: {
    Picture,
    Button,
    Swiper,
    SwiperSlide
  },
  setup() {
    return {
      Thumbs,
      EffectFade,
      modules: [EffectFade, Thumbs],
    };
  },
  created() {
    this.rentClassRequest();

  },
  methods: {
    setFirstSlide(event) {
      const firstSlide = event.target.querySelector('.first-slide');
      const currentSlide = event.target.querySelector('.swiper-slide-active');

      currentSlide.classList.remove('swiper-slide-active');
      firstSlide.classList.add('swiper-slide-active');
    },
    pushToCatalog(rclass) {
      if (this.$metrika) {
        this.$metrika.reachGoal('main_click_tocatalog');
      }
      this.$router.push({ path: '/ul/catalog', query: { rclass: `${rclass}` } });
    },
    rentClassRequest() {
      this.axios.get(
        `${links.API}/rent-classes`
      )
        .then(async (response) => {
          const newCars = [];

          response.data.forEach((car) => {
            const some = defaultCars.find((el) => {
              return el.id === car.id;
            });

            if (some) {
              car = { ...car, ...some };

              newCars.push(car);
            }
          });

          newCars.sort((a, b) => {
            return a.month_price - b.month_price;
          });

          this.cars = newCars.filter((el) => el.name && el.brand && el.model);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showService(val) {
      switch (val) {
        case 1:
          return 'Страховка КАСКО и ОСАГО';
        case 2:
          return 'Хранение и замена шин';
        case 3:
          return 'Техническое обслуживание';
        case 4:
          return 'Постановка на учет и оплата налогов';
        case 5:
          return '12 месяцев';
        case 6:
          return 'Постановка на&nbsp;учет в&nbsp;ГИБДД';
        case 7:
          return 'Телематический блок';
      }
    }
  },
  data() {
    return {
      cars: [],
    };
  }
};
</script>
