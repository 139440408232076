<template>
  <div class="progress-circle">
    <svg class="progress-circle__fill" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="20" stroke="#A5A5A5" stroke-width="4"></circle>
    </svg>
    <template v-if="active">
      <svg class="progress-circle__scale" :class="{'progress-circle__scale--error': error}" :style="{strokeDasharray: stroke }" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="22" cy="22" r="20" transform="rotate(-90 22 22)" fill="transpatrent" stroke="#2E58F5" stroke-width="4"></circle>
      </svg>
      <svg class="progress-circle__point" :style="{transform: 'rotate(' + degree + 'deg)' }" width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle r="2.5" transform="matrix(1 0 0 -1 24 3)" fill="#FCFCFC" stroke="#2E58F5"/>
      </svg>
      <svg class="progress-circle__error" :class="{ 'progress-circle__error--active': error }" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M31 31 17 17m14 0L17 31" stroke="red" stroke-width="3" stroke-linecap="round"/>
      </svg>
    </template>
    <svg class="progress-circle__complete" :class="{ 'progress-circle__complete--active': completed }" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="20" stroke="#17A400" stroke-width="4"></circle>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M28.2442 15.2141C28.556 14.9036 28.9768 14.7288 29.4157 14.7275C29.8546 14.7263 30.2764 14.8988 30.5898 15.2076C30.9033 15.5165 31.0832 15.9369 31.0906 16.378C31.0981 16.8191 30.9326 17.2454 30.6298 17.5649L21.7379 28.7363C21.5851 28.9018 21.4007 29.0346 21.1956 29.1267C20.9906 29.2189 20.7691 29.2685 20.5446 29.2727C20.32 29.2769 20.0969 29.2355 19.8886 29.151C19.6803 29.0666 19.4911 28.9408 19.3323 28.7811L13.4408 22.8573C13.2766 22.7036 13.145 22.5182 13.0537 22.3123C12.9624 22.1063 12.9133 21.884 12.9093 21.6585C12.9054 21.4331 12.9466 21.2091 13.0306 21C13.1147 20.791 13.2397 20.601 13.3984 20.4416C13.557 20.2822 13.746 20.1565 13.954 20.072C14.162 19.9876 14.3848 19.9461 14.6091 19.9501C14.8334 19.954 15.0546 20.0034 15.2595 20.0952C15.4645 20.1869 15.6489 20.3192 15.8018 20.4842L20.4661 25.17L28.2019 15.2634C28.2157 15.246 28.2306 15.2296 28.2465 15.2141H28.2442Z" fill="#17A400"></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ProgressCircle',
  props: {
    active: {},
    progress: {},
    maxProgress: {},
    completed: {},
    error: {}
  },
  computed: {
    percent() {
      return (this.progress/this.maxProgress) * 100;
    },
    stroke() {
      return this.percent * 1.26 + 130;
    },
    degree() {
      return (360/100) * this.percent;
    }
  }
};
</script>