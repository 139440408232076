<template>
  <swiper class="car__slider" :modules="[Thumbs]" :thumbs="{ swiper: thumbsSwiper, autoScrollOffset: 1 }">
    <swiper-slide class="car__slide" v-for="(img, index) in slides" :key="index">
      <picture>
        <img :src="img" alt="Car" title="" />
      </picture>
    </swiper-slide>
  </swiper>
  <swiper class="car__thumbs" :modules="[Thumbs]" watch-slides-progress slides-per-view="auto" @swiper="setThumbsSwiper">
    <swiper-slide class="car__slide" v-for="(img, index) in slides" :key="index">
      <picture>
        <img :src="img" alt="Car" title="" />
      </picture>
    </swiper-slide>
  </swiper>
</template>

<script>
import { ref } from 'vue';
import { Thumbs } from '@Swiper';
import { Swiper, SwiperSlide } from '@SwiperVue';

export default {
  name: 'CarSlider',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    slides: {},
  },
  setup() {
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };
    return {
      Thumbs,
      thumbsSwiper,
      setThumbsSwiper,
    };
  },
};
</script>