<template>
  <footer class="landing-footer">
    <div class="landing-footer__content">
      <div class="landing-footer__inform">
        <aos-vue placement="top-bottom" animation="fade-up" :duration="800" :once="true">
          <div class="landing-footer__logo">
            <Picture path="icons/logo-autodom" ext="png" name="Logo"></Picture>
          </div>
        </aos-vue>
        <div class="landing-footer__contacts">
          <aos-vue placement="top-bottom" animation="fade-up" :duration="800" :once="true">
            <div class="landing-footer__contact">
              <div class="landing-footer__label">Помощь</div>
              <a class="landing-footer__link" href="mailto:help@autopodpiska.ru">help@autopodpiska.ru</a>
            </div>
          </aos-vue>
          <aos-vue placement="top-bottom" animation="fade-up" :duration="800" :once="true">
            <div class="landing-footer__contact">
              <div class="landing-footer__label">Вопросы о приложении</div>
              <a href="mailto:help@autopodpiska.ru" class="landing-footer__link">
                help@autopodpiska.ru
              </a>
            </div>
          </aos-vue>
        </div>
      </div>
      <aos-vue placement="top-bottom" animation="fade-up" :duration="800" :once="true">
        <div class="landing-footer__support">
          <div class="landing-footer__logo">
            <Picture path="icons/logo-autodom" ext="png" name="Logo"></Picture>
          </div>
          <div class="landing-footer__title">
            Информационная поддержка
          </div>
          <a href="tel:8 (499) 270-07-60" class="landing-footer__tel">
            8 (499) 270-07-60
          </a>
          <a href="mailto:help@autopodpiska.ru" class="landing-footer__link">
            help@autopodpiska.ru
          </a>
        </div>
      </aos-vue>
    </div>
    <aos-vue placement="top-bottom" animation="fade-up" :duration="800" :once="true">
      <div class="landing-footer__copyright">
        <div class="landing-footer__copyright-left">
          &#169; 2021-
          <span class="landing-footer__current-year">
            {{ currentYear }}
          </span>
          <a href="https://autopodpiska.ru/" class="landing-footer__ompany-name">&nbsp; Autopodpiska.ru</a>
        </div>
        <div class="landing-footer__copyright-right">
          ООО &laquo;Автосмарт&raquo; г. Москва, ул. Зорге,17&nbsp;ИНН 7714467058
        </div>
      </div>
    </aos-vue>
  </footer>
</template>

<script>
import Picture from '@/pages/landing/components/Picture';

export default {
  name: 'Footer',
  components: {
    Picture
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>