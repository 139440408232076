<template>
  <transition name="fade">
    <div v-if="isShown" class="popup popup--dailog">
      <div class="popup__overlay">
      </div>
      <div class="popup__thanks">
        <div class="popup__content">
          <button class="popup__close" @click="isShown = false" type="button">
            <svg>
              <use xlink:href="@/assets/images/sprites.svg#icon-close"></use>
            </svg>
          </button>
          <div class="popup__icon">
            <svg>
              <use xlink:href="@/assets/images/sprites.svg#icon-check"></use>
            </svg>
          </div>
          <div class="popup__title">Cпасибо, мы получили вашу заявку.
          </div>
          <div class="popup__text">Наш менеджер свяжется с Вами!
          </div>
          <div class="popup__button">
            <button class="button button--thanks" @click="isShown = false" type="button" onclick="">Спасибо!
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CarOrderPopup',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      isShown: this.show,
      loading: false
    };
  },
  watch: {
    show(value) {
      this.isShown = value;
    },
    isShown(value) {
      if (value === false) {
        this.$store.dispatch('call/show', false);
      }
    },
  },
};
</script>