<template>
  <div class="landing-who">
    <aos-vue placement="top-bottom" animation="fade-up" :once="true" :duration="800">
      <div class="landing-who__content">
        <div class="landing-who__query">
          Кому подойдет?
        </div>
        <div class="landing-who__title">
          Подписка для тех кто:
        </div>
      </div>
    </aos-vue>
    <aos-vue placement="top-bottom" animation="fade-up" :once="true" :duration="800">
      <Swiper class="landing-who__slider" :slidesPerView="slidesPerView" :pagination="pagination" @swiper="setSwiper" :modules="modules">
        <SwiperSlide class="landing-who__slide" v-for="slide, index in slider" :key="index">
          <div class="landing-who__icon">
            <Picture :path="`icons/who-${index + 1}`" ext="png" name="Icon">
            </Picture>
          </div>
          <div class="landing-who__subtitle" v-html="slide.subtitle">
          </div>
          <div class="landing-who__description" v-html="slide.description">
          </div>
        </SwiperSlide>
      </Swiper>
      <div class="landing-who__pagination"></div>
    </aos-vue>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from '@SwiperVue';
import { Pagination } from '@Swiper';
import Picture from './Picture';

export default {
  name: 'Who',
  components: {
    Picture,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const whoSwiper = ref(null);

    const setSwiper = (swiper) => {
      whoSwiper.value = swiper;
    };
    
    return {
      slidesPerView: 'auto',
      pagination: {
        el: '.landing-who__pagination',
        clickable: true,
        renderBullet: (index, className) => {
          return `<span class="${className} landing-who__bullet landing-who__bullet--${index + 1}">0${index + 1}</span>`;
        },
      },
      whoSwiper,
      setSwiper,
      modules: [Pagination],
    };
  },
  data() {
    return {
      slider: [
        {
          subtitle: 'Ценит комфорт',
          description: 'Кому не&nbsp;хочется тратить силы и&nbsp;время на&nbsp;заботы об&nbsp;автомобиле',
        },
        {
          subtitle: 'Умеет считать деньги',
          description: 'Если не&nbsp;хочется замораживать деньги в&nbsp;пассиве',
        },
        {
          subtitle: 'Ценит конфиден&shy;циальность',
          description: 'Если важно, чтобы ваши данные были в&nbsp;безопасности',
        },
        {
          subtitle: 'Предпочитает анонимность',
          description: 'Когда ваш текущий автомобиль&nbsp;&mdash; ваша личная информация',
        },
        {
          subtitle: 'Любит запах нового автомобиля',
          description: 'Если хочется новый авто каждые 6/12 месяцев и&nbsp;с&nbsp;бантиком при&nbsp;выдаче',
        },
        {
          subtitle: 'Хочет попробовать новое',
          description: 'Если желаете оценить новинки без рисков. Кстати, если понравится, авто можно будет выкупить',
        },
        {
          subtitle: 'Любит проверенные бренды',
          description: 'Если ваш выбор&nbsp;&mdash; <br>новые модели BMW, Mercedes-Benz и&nbsp;других производителей из&nbsp;Европы и&nbsp;США',
        },
      ],
    };
  }
};
</script>