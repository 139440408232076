<template>
  <tr class="raw">
    <td>{{ raw.description }}</td>
    <td>{{ payment_date.toLocaleDateString("ru-RU", {dateStyle: "short"}) }}</td>
    <td>{{ raw.amount_plan }}</td>
    <td class="status" :class="{'paid': isPaid}">{{ getStatus() }}</td>
  </tr>
</template>

<script>
export default {
  name: 'Raw',
  props: {
    raw: Object,
    isThead: Boolean,
  },
  data () {
    return {
      amount_plan: this.raw.amount_plan,
      amount_fact: this.raw.amount_fact,
      payment_date: new Date(this.raw.payment_date),
      isPaid: false
    };
  },
  methods: {
    getStatus() {
      if (this.amount_plan <= this.amount_fact) {
        this.isPaid = true;
        return 'Оплачен';
      }
      return 'Не оплачен';
    }
  }
};
</script>
