<template>
  <div class="card__pagination">{{ swiper.realIndex + 1 }}/{{ this.length }}</div>
</template>
<script>
import { useSwiper } from '@SwiperVue';

export default {
  props: {
    length: {}
  },
  setup() {
    const swiper = useSwiper();

    return {
      swiper,
    };
  },
};
</script>