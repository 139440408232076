import * as types from '../mutation-types';

const namespaced = true;

// state
const state = {
  show: false
};

// getters
const getters = {
  show: state => state.show,
};

// mutations
const mutations = {
  [types.SHOW_CALL_REQUEST](state, payload) {
    state.show = payload;
  },
};

//actions
const actions = {
  show({commit}, payload) {
    commit(types.SHOW_CALL_REQUEST, payload);
  }
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};