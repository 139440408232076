<template>
  <main class="home" role="main">
    <section class="home__section home__section--profile" id="profile">
      <div class="profile">
        <div class="profile__head">
          <div class="profile__title">Добро пожаловать в&nbsp;Личный Кабинет</div>
        </div>
        <div class="profile__steps">
          <div class="profile__text profile__text--steps">Заполните информацию, чтобы оформить подписку на&nbsp;свой идеальный автомобиль.
          </div>
          <scoring-contacts :active="step === 1" :profile="profile" :completed="step > 1" @readyStep="nextStep" @saveStep="saveStep"></scoring-contacts>
          <scoring-documents :active="step === 2" :completed="step > 2" :loadingProcess="documentsLoading" @readyStep="nextStep2" @saveStep="saveStep" :finalStatus="finalStatus"></scoring-documents>
          <scoring-address :active="step === 3" :completed="step > 3" @readyStep="nextStep"></scoring-address>
          <scoring-second-driver :active="step === 4" :completed="step > 4" @readyStep="nextStep"></scoring-second-driver>
          <scoring-card :active="step === 5" :completed="step > 5" @readyStep="nextStep"></scoring-card>
          <scoring-final :active="step === 6" :profile="profile"></scoring-final>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import * as links from '../store/links';
import ScoringContacts from '../components/scoring/ScoringContacts';
import ScoringDocuments from '../components/scoring/ScoringDocuments';
import ScoringAddress from '../components/scoring/ScoringAddress';
import ScoringSecondDriver from '../components/scoring/ScoringSecondDriver';
import ScoringCard from '../components/scoring/ScoringCard';
import ScoringFinal from '../components/scoring/ScoringFinal';

export default {
  name: 'profile',
  components: {
    ScoringFinal,
    ScoringCard,
    ScoringAddress,
    ScoringSecondDriver,
    ScoringDocuments,
    ScoringContacts
  },
  data() {
    return {
      photos: [],
      step: 1,
      finalStatus: false,
      profile: {
        area: '',
        city: '',
        street: '',
        house: '',
        apartment: '',
        firstName: '',
        lastName: '',
        middleName: '',
        birthDate: '',
        driverLicenseNumber: '',
        driverLicenseDate: '',
        phone: '',
        email: ''
      },
      documentsLoading: false
    };
  },
  mounted() {
    if (this.$route.params.step === 'final') {
      this.step = 6;
      this.saveStep(this.step);
    } else {
      this.profileRequest();
    }
  },
  methods: {
    checkFinalError() {
      if (this.step) {
        this.axios.get(
          `${links.API}/user/scoring/status`,
          {
            loading: { message: 'Trying to login...' },
          }
        )
          .then(async (response) => {
            if (!response.data.scored && this.step > 2) {
              this.finalStatus = true;
              this.step = 2;
              this.saveStep();
              return true;
            } else if (response.data.scored && this.step <= 2) {
              this.step = 3;
              this.saveStep();
              return true;
            }
          })
          .catch((error) => {
            console.error(error);
            this.$store.dispatch('auth/checkAuth', error);
          });
        }
      return false;
    },
    nextStep() {
      // if (this.checkFinalError()) {
      //   return;
      // }

      this.step = this.step + 1;

      if (this.step === 3) {
        this.documentsLoading = false;
      }

      if (this.step !== 2) {
        this.saveStep();
      }
    },
    nextStep2(data) {
      if (data) {
        Object.assign(this.profile, data);
      }

      this.step = this.step + 1;

      if (this.step === 3) {
        this.documentsLoading = false;
      }

      this.saveStep();
    },
    async saveStep(id) {
      const step = id ? id : this.step;
      this.axios.patch(
        `${links.API}/user`,
        {
          step: step
        },
        {
          loading: {message: 'Trying to login...'}
        }
      )
        .catch((error) => {
          this.loading = false;
          console.error(error);
          this.$store.dispatch('auth/checkAuth', error);
        });
    },
    async profileRequest() {
      this.axios.get(
          `${links.API}/user`,
          {
            loading: {message: 'Trying to login...'}
          }
      )
          .then(async(response) => {
            const creds = response.data;
            this.profile.name = creds.name ? creds.name : '';
            this.profile.email = creds.email ? creds.email : '';
            this.profile.phone = creds.phone ? `+${creds.phone}` : '';

            if (creds.step && creds.step !== 1) {
              this.step = creds.step;

              // if(this.checkFinalError()) {
              //   return;
              // }

              if (this.step === 2) {
                this.documentsLoading = true;
              }
            }
          })
          .catch((error) => {
            console.error(error);
            this.$store.dispatch('auth/checkAuth', error);
          });
    }
  },
};
</script>
