<template>
  <div class="profile__step profile__step--6" :class="{'is-active': active}">
    <div class="profile__headline">
      <div class="profile__circle">
        <progress-circle :active="active" :progress="progress" :max-progress="2" :error="false" :completed="false"/>
      </div>
      <div>
        <div class="profile__title">Шаг 6</div>
        <div class="profile__text">Подтверждение заказа</div>
      </div>
    </div>
    <div v-if="id" class="profile__form profile__form--car">
      <div class="profile__car">
        <div class="profile__slider">
          <card-slider v-if="loaded" :slides="car.photos"></card-slider>
        </div>
        <div class="profile__specification">
          <div class="profile__subtitle">{{ car.make }} {{ car.version }}</div>
          <div class="profile__engine">{{ getVolume(car.engine_volume) }}л, {{ car.engine_horse_power }} л.с.</div>
          <div class="profile__drivetrain">{{ car.driving_gear_type }} привод</div>
          <div class="profile__consumption">{{ car.fuel_type }}</div>
          <router-link class="profile__close" to="/catalog">Сменить автомобиль</router-link>
        </div>
      </div>
      <div class="profile-dealership">
        <div class="profile-dealership__fields">
          <fieldset class="profile__fieldset">
            <legend class="profile__legend">Дилерский центр</legend>
            <v-select
              v-model="shop"
              label="address"
              :options="shops"
              placeholder="Выбрать центр"
              :filterable="false"
            >
              <template #selected-option-container="{ option }">
                <template v-if="option && Object.keys(option).length !== 0">
                  <span class="vs__selected">{{ option.address }}</span>
                </template>
              </template>
            </v-select>
            <svg class="profile-dealership__select-icon" width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 1L9.8 11L1 1.43478" stroke="#0A1C2B" stroke-linecap="round"/>
            </svg>
          </fieldset>
          <div class="profile-dealership__trade-in">
            <input v-model="tradeIn" class="filter__input" id="trade-in" type="checkbox" name="trade-in"/>
            <label class="filter__checkbox" for="trade-in">
              <span class="filter__text">Интересует Trade-in</span>
            </label>
          </div>
        </div>
        <ScoringPromoCode
          :id="Number(this.id)"
          :ratePlan="this.ratePlan"
          :isError="isError"
          :isSuccess="isSuccess"
          :loading="loading"
          @toggleStatus="togglePromoCodeState"
          @getPriceWithDiscount="getPriceWithDiscount"
          @cancelDiscount="cancelDiscount"
          @setDiscountId="setDiscountId"
        />
        <div class="profile__button profile__button--5">
          <button
            class="button button--5"
            type="button"
            @click="bookCar"
            :disabled="loading"
          >
            Забронировать за {{ formatPrice(this.finalPricePerMonth) }} руб.
          </button>
        </div>
        <div
          class="profile__discount"
          :class="{active: isSuccess}"
        >
          Ежемесячный платеж снижен на {{ formatPrice(this.discountCount) }} рублей
        </div>
      </div>
    </div>
    <div v-else class="profile__form profile__form--catalog">
      <div class="profile__text">Для оформления подписки выберите интересующий вас автомобиль</div>
      <div class="profile__button profile__button--6">
        <router-link class="button button--6" to="/catalog">К каталогу</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import * as links from '../../store/links';
import {mapGetters} from 'vuex';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import CardSlider from '@/components/CardSlider';
import ProgressCircle from '@/components/ProgressCircle';
import ScoringPromoCode from '@/components/scoring/ScoringPromoCode';
export default {
  name: 'ScoringFinal',
  components: {
    CardSlider,
    ProgressCircle,
    vSelect,
    ScoringPromoCode
  },
  data() {
    return {
      car: {},
      rclass: {},
      loaded: false,
      carDefault: {
        photos: [
          require('@/assets/images/catalog/car-placeholder.png'),
        ],
      },
      isError: false,
      isSuccess: false,
      isDiscount: false,
      loading: false,
      profileInfo: {},
      shop: {},
      shops: [],
      tradeIn: true,
      promo_id: null,
      discountCount: null,
      finalPricePerMonth: null
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    profile: {}
  },
  computed: {
    progress() {
      return this.id ? 1 : 0;
    },
    ...mapGetters({
      id: 'cars/chosenCar',
    })
  },
  watch: {
  },
  methods: {
    setDiscountId (id) {
      this.promo_id = id;
    },
    getPriceWithDiscount(priceWithDiscount) {
      this.discountCount = Math.round(this.ratePlan.price - priceWithDiscount);
      this.finalPricePerMonth = Math.round(priceWithDiscount);
    },
    cancelDiscount() {
      this.isError = false;
      this.isSuccess = false;
      this.isDiscount = false;
      this.discountCount = null;
      this.finalPricePerMonth = this.ratePlan.price;
    },
    togglePromoCodeState(value) {
      this.isError = value;
      this.isSuccess = !value;
    },
    prepareData() {
      if (this.promo_id) {
        this.profileInfo.promo_code_id = this.promo_id;
      }

      this.profileInfo.site_id = this.shop.id;
      this.profileInfo.rate_plan_id = this.ratePlan.id;
      this.profileInfo.car_id = Number(this.id);
    },
    bookCar() {
      this.loading = true;
      this.$store.dispatch('loader/show', true);

      if (this.$metrika) {
        this.$metrika.reachGoal('lkscoring_click_6step_confirm');
      }

      this.prepareData();

      this.axios.post(
          `${links.API}/user/booking`,
          this.profileInfo,
          {
            loading: {message: 'Trying to login...'},
          }
      )
      .then(async(response) => {
        this.loading = false;
        this.$store.dispatch('loader/show', false);

        const profile = this.profile;
        profile.car = this.id;
        await this.$store.dispatch('auth/fetchUser');

        if (response.data.payment_url) {
          window.location = response.data.payment_url;
        } else {
          this.$router.push('/profile');
        }
      })
      .catch((error) => {
        console.error(error);
        this.$store.dispatch('loader/show', false);
        this.$store.dispatch('auth/checkAuth', error);
      });
    },
    carRequest() {
      this.axios.get(
          `${links.API}/cars/${this.id}`,
          {
            loading: {message: 'Trying to login...'}
          }
      )
      .then(async(response) => {
        this.car = response.data;
        this.setDefault();
        this.shop = {
          id: 20,
          descr: 'Порше Ленинский',
          town: 'Москва',
          address: 'г. Москва, Ленинский пр. 107'
        };
        // this.siteRequest();
      })
      .catch((error) => {
        console.error(error);
      });
    },
    rentClassRequest() {
      this.axios.get(
          `${links.API}/rent-classes/${this.car.rent_class_id}`,
          {
            loading: {message: 'Trying to login...'}
          }
      )
      .then(async(response) => {
        this.rclass = response.data;

        if (this.rclass && this.rclass.photos.length) {
          this.car.photos =  this.rclass.photos;
        } else {
          this.car.photos = this.carDefault.photos;
        }

        this.loaded = true;
      })
      .catch((error) => {
        console.error(error);
      });
    },
    siteRequest() {
      this.axios.get(
          `${links.API}/sites/${this.car.model_id}`,
      )
      .then(async(response) => {
        if (response.data) {
          this.shops = response.data;
          this.shop = this.shops[0];
        }
      })
      .catch((error) => {
        console.error(error);
        this.$store.dispatch('auth/checkAuth', error);
      });
    },
     ratePlansRequest() {
      if (localStorage.getItem('ratePlan')) {
        this.ratePlan = JSON.parse(localStorage.getItem('ratePlan'));
      }

      this.axios.get(
      `${links.API}/rate-plans/${this.id}`,
      {
        loading: {message: 'Trying to login...'}
      }
        )
      .then(async(response) => {
        this.ratePlans = response.data;
        if (this.ratePlan) {
          this.ratePlans.forEach((plan) => {
            if (plan.id === this.ratePlan.id) {
              this.ratePlan = plan;
              return;
            }
          });
        } else {
          this.ratePlan = this.ratePlans[0];
        }
      })
      .catch((error) => {
        console.error(error);
        this.$store.dispatch('auth/checkAuth', error);
      })
      .finally(this.finalPricePerMonth = this.ratePlan.price);
    },
    getVolume(num) {
      return Number((num*0.001).toFixed(2));
    },
    setDefault() {
      if (!this.car.photos.length) {
        this.rentClassRequest();
      } else {
        this.loaded = true;
      }
    },
    formatPrice(price) {
      return price > 0 ? price.toLocaleString('ru-RU') : price;
    },
  },
  mounted() {
    if (!this.id.length) {
      this.$store.dispatch('cars/fetchChosenCar');
    }

    if (this.id) {
      this.carRequest();
      this.ratePlansRequest();
    }

  }
};
</script>
