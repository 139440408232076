<template>
  <div class="landing-faq">
    <aos-vue placement="top-bottom" animation="fade-up" :duration="800" :once="true">
      <div class="landing-faq__query">
        Остались вопросы?
      </div>
    </aos-vue>
    <aos-vue placement="top-bottom" animation="fade-up" :duration="800" :once="true">
      <div class="landing-faq__title">
        FAQ и документы
      </div>
    </aos-vue>
    <div class="landing-faq__content">
      <aos-vue placement="top-bottom" animation="fade-up" :duration="800" :once="true">
        <div class="landing-faq__accordion">
          <div v-for="item, index in accordion" :key="index" class="landing-faq__line">
            <div class="landing-faq__question" @click="openAccordion">
              <span v-html="item.question">
              </span>
              <svg>
                <use xlink:href="@/assets/images/sprites.svg#arrow-down"></use>
              </svg>
            </div>
            <div class="landing-faq__answer">
              <span v-html="item.answer">
              </span>
            </div>
          </div>
        </div>
      </aos-vue>
      <aos-vue placement="top-bottom" animation="fade-up" :duration="800" :once="true">
        <div class="landing-faq__document">
          <a v-for="document, index in documents" :key="index" class="landing-faq__label" :href="document.url"
            target="_blank">
            <span>
              {{ document.label }}
            </span>
            <svg>
              <use xlink:href="@/assets/images/sprites.svg#icon-download"></use>
            </svg></a>
        </div>
      </aos-vue>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Faq',
  methods: {
    openAccordion(event) {
      event.target.closest('.landing-faq__line').classList.toggle('is-show');
    }
  },
  mounted() {
    // document.querySelector('.faq__login').addEventListener('click', () => {
    //   if (this.$metrika) {
    //     this.$metrika.reachGoal('main_faq_click_lk');
    //   }

    //   this.$router.push({ name: 'Catalog' })
    // })
  },
  data() {
    return {
      accordion: [
        {
          question: 'Что такое Подписка на&nbsp;автомобиль?',
          answer: 'Подписка&nbsp;&mdash; это альтернативный способ приобретения автомобиля, когда за&nbsp;ежемесячный платеж вы&nbsp;получаете новый автомобиль и&nbsp;пользуетесь им&nbsp;в&nbsp;течение выбранного срока. Мы&nbsp;берем на&nbsp;себя все расходы: регистрируем в&nbsp;ГИБДД, оплачиваем налоги, страхуем автомобиль по&nbsp;КАСКО и&nbsp;ОСАГО, проводим техническое обслуживание и&nbsp;обеспечиваем шинный сервис.',
        },
        {
          question: 'Включают&nbsp;ли ежемесячные платежи НДС?',
          answer: 'В&nbsp;случае оформления подписки на&nbsp;компанию, все платежи включают НДС, который компания может принимать к&nbsp;зачету. Кроме того, так как авто находится на&nbsp;балансе сервиса подписки, транспортный налог и&nbsp;налог на&nbsp;имущество не&nbsp;надо оплачивать отдельно&nbsp;&mdash; все эти расходы входят в&nbsp;ежемесячные платежи, которые ложатся на&nbsp;затраты компании и&nbsp;снижают налоговую базу по&nbsp;чистой прибыли.',
        },
        {
          question: 'Чем подписка отличается от&nbsp;лизинга?',
          answer: 'Подписка выгоднее кредита или лизинга, так как мы&nbsp;не&nbsp;требуем первоначального взноса и&nbsp;не&nbsp;навязываем дополнительное оборудование и&nbsp;дорогостоящие страховые продукты. Кроме того, по&nbsp;подписке вы&nbsp;получаете абсолютно новый автомобиль и&nbsp;не&nbsp;тратите время и&nbsp;деньги на&nbsp;техническое обслуживание, замену шин, страхование и&nbsp;уплату налогов.',
        },
        {
          question: 'Как оформить подписку?',
          answer: 'Для оформления подписки оставьте заявку на&nbsp;выбранный автомобиль, указав ИНН компании и&nbsp;контактные данные.',
        },
        {
          question: 'Кто может пользоваться авто по&nbsp;подписке для ЮЛ?',
          answer: 'Для каждого автомобиля допускается до&nbsp;2&nbsp;водителей: минимальный возраст 25&nbsp;лет, водительский стаж от&nbsp;3&nbsp;лет, гражданство РФ.',
        },
        {
          question: 'На&nbsp;какой срок можно взять автомобиль в&nbsp;подписку? Есть&nbsp;ли ограничения по&nbsp;пробегу?',
          answer: 'Подписка подразумевает получение автомобиля от&nbsp;6&nbsp;до&nbsp;24&nbsp;месяцев, максимально допустимый пробег составляет от&nbsp;15&nbsp;000&nbsp;км до&nbsp;60&nbsp;000&nbsp;км. При превышении данного пробега автомобиль возвращается в&nbsp;сервис подписки. При этом необходимо выплатить все оставшиеся ежемесячные платежи.',
        },
        {
          question: 'Могу&nbsp;ли я&nbsp;выехать на&nbsp;автомобиле по&nbsp;подписке за&nbsp;пределы зоны эксплуатации?',
          answer: 'Выезд за&nbsp;границы указанной <a href="https://autopodpiska.ru/#coverage">территории эксплуатации</a> в&nbsp;пределах России возможен после дополнительного согласования. Просим вас заполнить <a href="https://forms.yandex.ru/u/607d6e28b1b2b54a994fec3b/" target="_blank">форму</a> на&nbsp;сайте или написать нам на&nbsp;имейл: <a href="mailto:help@autopodpiska.ru">help@autopodpiska.ru</a>',
        },
        {
          question: 'Как записаться на&nbsp;ТО/шиномонтаж? Куда обращаться при ДТП?',
          answer: 'Для записи на&nbsp;сервисное обслуживание, сезонный шиномонтаж, а&nbsp;также в&nbsp;случае ДТП позвоните на&nbsp;нашу горячую линию: <a href="tel:+74951269058">+7-495-126-90-58</a>.',
        },
        {
          question: 'Кто оплачивает штрафы?',
          answer: 'Самостоятельно отслеживаете и&nbsp;оплачиваете штрафы, выставленные на&nbsp;ваш авто по&nbsp;подписке.',
        },
        {
          question: 'История Пользовательских соглашений',
          answer: '<a href="https://api.autopodpiska.ru/document/contract_until_30april2024" target="_blank">До 6 мая 2024 года</a>',
        },
      ],
      documents: [
        {
          label: 'Актуальное пользовательское соглашение',
          url: 'https://api.autopodpiska.ru/document/contract_from_30april2024',
        },
        {
          label: 'Анкета пользователя',
          url: 'https://api.autopodpiska.ru/document/user_form',
        },
      ],
    };
  }
};
</script>