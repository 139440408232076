<template>
  <div class="profile__step profile__step--1" :class="{'is-active': active, 'is-complete': completed}">
    <div class="profile__headline">
      <div class="profile__circle">
        <progress-circle :active="active" :progress="progress" :max-progress="3" :error="v$.$error" :completed="completed"></progress-circle>
      </div>
      <div>
        <div class="profile__title">Шаг 1</div>
        <div class="profile__text">Контактная информация</div>
      </div>
    </div>
    <form class="profile__form" @submit.prevent="contactsRequest">
      <fieldset class="profile__fieldset" :class="{ 'is-error': v$.name.$error, 'valid': !v$.name.$invalid }">
        <legend class="profile__legend">ФИО</legend>
        <input v-model="name" class="profile__input" type="text" name="name" placeholder="Иван Иванович Иванов"/>
        <div class="profile__error">Неверный формат имени</div>
      </fieldset>
      <fieldset class="profile__fieldset" :class="{ 'is-error': v$.email.$error, 'valid': !v$.email.$invalid }">
        <legend class="profile__legend">E-mail</legend>
        <input v-model="email" class="profile__input" type="email" name="email" placeholder="name@email.ru"/>
        <div class="profile__error">Неверный формат электронной почты</div>
      </fieldset>
      <fieldset class="profile__fieldset" :class="{ 'is-error': v$.phone.$error, 'valid': !v$.phone.$invalid }" disabled>
        <legend class="profile__legend">Телефон</legend>
        <input v-model="phone" class="profile__input" type="tel" name="phone" placeholder="+7 ***" disabled/>
        <div class="profile__error">Неверный формат телефона</div>
      </fieldset>
      <div class="profile__button profile__button--1">
        <button class="button button--1" type="submit" :disabled="loading">Подтвердить</button>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import * as links from '@/store/links';
import ProgressCircle from '@/components/ProgressCircle';

export default {
  name: 'ScoringContacts',
  components: {
    ProgressCircle
  },
  setup () {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      complete: false,
      name: '',
      email: '',
      phone: '',
      loading: false
    };
  },
  computed: {
    progress() {
      return !this.v$.name.$invalid + !this.v$.email.$invalid + !this.v$.phone.$invalid;
    }
  },
  validations () {
    return {
      name: {required},
      email: {required, email},
      phone: {required}
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    profile: {
      type: Object
    },
    completed: {}
  },
  watch: {
    profile: {
      handler(val) {
        if (val) {
          this.name = val.name ? val.name : '';
          this.email = val.email ? val.email : '';
          this.phone = val.phone ? val.phone : '';
        }
      },
      deep: true
    },
    completed(val) {
      if (val) {
        this.complete = val;
      }
    }
  },
  methods: {
    async contactsRequest() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) return;

      this.loading = true;
      if (this.$metrika) {
        this.$metrika.reachGoal('lkscoring_click_1step_confirm');
      }
      this.axios.post(
          `${links.API}/user`,
          {
            name: this.name,
            email: this.email
          },
          {
            loading: {message: 'Trying to login...'}
          }
          )
            .then(async() => {
              this.loading = false;
              this.$emit('readyStep', {
                name: this.name,
                email: this.email,
                phone: this.phone
              });
              this.$emit('saveStep');
              this.complete = true;
            })
            .catch((error) => {
              this.loading = false;
              console.error(error);
            });
    },
  }
};
</script>