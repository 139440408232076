<template>
  <div class="landing-about">
    <Swiper :pagination="pagination" @swiper="setSwiper" :autoplay="{
      delay: 8000,
      disableOnInteraction: false
    }" :modules="modules" class="landing-about__slider" :allowSlidePrev="false" slidesPerView="auto" loop>
      <SwiperSlide v-for="slide, index in slider" :key="index" class="landing-about__slide">
        <div class="landing-about__content">
          <div class="landing-about__query" v-html="slide.query">
          </div>
          <div class="landing-about__title" v-html="slide.title">
          </div>
          <div class="landing-about__text" v-html="slide.text">
          </div>
          <div class="landing-about__button">
            <Button href="#catalog" @click.prevent="scrollTo" :name="slide.button"></Button>
          </div>
        </div>
        <div class="landing-about__bg">
          <Picture mobile tablet :path="`about/slide-${index + 1}`" ext="png" :name="`Slide ${index + 1}`"></Picture>
        </div>
      </SwiperSlide>
      <div class="landing-about__control">
        <button @click="aboutSwiper.slideNext()" class="landing-about__next">
          <div class="landing-about__arrow">
            <svg>
              <use xlink:href="@/assets/images/sprites.svg#arrow-next"></use>
            </svg>
          </div>
        </button>
        <div class="landing-about__pagination"></div>
        <div class="landing-about__progress">
          <div ref="aboutBar" class="landing-about__bar"></div>
        </div>
      </div>
    </Swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from '@SwiperVue';
import { Pagination, Autoplay } from '@Swiper';
import { gsap } from 'gsap';
import Button from './Button';
import Picture from './Picture';
import { ref } from 'vue';

export default {
  name: 'About',
  components: {
    Button,
    Picture,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const aboutSwiper = ref(null);

    const setSwiper = (swiper) => {
      aboutSwiper.value = swiper;
    };

    return {
      pagination: {
        el: '.landing-about__pagination',
        renderBullet: (index, className) => {
          return `<span class="${className} landing-about__bullet landing-about__bullet--${index + 1}">0${index + 1}</span>`;
        },
      },
      aboutSwiper,
      setSwiper,
      modules: [Pagination, Autoplay],
    };
  },
  mounted() {
    const tl = gsap.timeline();

    const aboutBar = this.$refs.aboutBar;

    tl.progress(0).to(aboutBar, {
      duration: 8,
      ease: 'linear',
      width: '100%',
      clearProps: 'all',
    });

    this.aboutSwiper.on('slideChangeTransitionStart', () => {
      tl.progress(0).kill();
    });

    this.aboutSwiper.on('slideChangeTransitionEnd', () => {
      tl.progress(0).to(aboutBar, {
        duration: 8,
        ease: 'linear',
        width: '100%',
        clearProps: 'all',
      });
    });

  },
  methods: {
    scrollTo(event) {
      const href = event.target.getAttribute('href');
      const selector = document.querySelector(href);

      gsap.timeline().to(window, {
        duration: 1.2,
        scrollTo: selector,
        ease: 'Power3.ease',
      });
    },
  },
  data() {
    return {
      slider: [
        {
          query: 'Новый премиум',
          title: 'Более 30 новых автомобилей в наличии',
          text: 'Выберите автомобиль в личном кабинете и оформите онлайн.',
          button: 'Время выбирать!',
        },
        {
          query: 'Почему АВТОDОМ',
          title: 'Geely, Exeed, Haval, Chery, Omoda',
          text: 'Выберете автомобиль в каталоге и оформите онлайн.',
          button: 'Время выбирать!',
        },
        {
          query: 'Почему АВТОDОМ',
          title: 'Максимальные комплектации',
          text: 'Получите больший комфорт от автомобиля по выгодным ценам.',
          button: 'Время выбирать!',
        },
      ],
    };
  }
};
</script>