export const LOGOUT = 'LOGOUT';
export const SAVE_TOKEN = 'SAVE_TOKEN';
export const SAVE_USER = 'SAVE_USER';
export const SAVE_RCLASS = 'SAVE_RCLASS';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const SHOW_CALL_REQUEST = 'SHOW_CALL_REQUEST';
export const SHOW_LOADING = 'SHOW_LOADING';

export const FETCH_CARS_SUCCESS = 'FETCH_CARS_SUCCESS';
export const FETCH_MODELS_SUCCESS = 'FETCH_MODELS_SUCCESS';
export const FETCH_COUNTS_SUCCESS = 'FETCH_COUNTS_SUCCESS';
export const FETCH_CLASSES_SUCCESS = 'FETCH_CLASSES_SUCCESS';
export const SET_CHOSEN_CAR = 'SET_CHOSEN_CAR';
export const SET_CARS = 'SET_CARS';
export const FETCH_FILTER_SUCCESS = 'FETCH_FILTER_SUCCESS';