<template>
  <div class="landing-steps">
    <aos-vue placement="top-bottom" animation="fade-up" :once="true" :duration="800">
      <div class="landing-steps__content">
        <div class="landing-steps__query">
          Почему подписка?
        </div>
        <div class="landing-steps__title">
          Преимущества для бизнеса
        </div>
      </div>
    </aos-vue>
    <aos-vue placement="top-bottom" animation="fade-up" :once="true" :duration="800">
      <Swiper class="landing-steps__slider" slidesPerView="auto">
        <SwiperSlide class="landing-service__slide" v-for="slide, index in slider" :key="index">
          <div class="landing-steps__number">
            <svg>
              <use xlink:href="@/assets/images/sprites.svg#icon-check"></use>
            </svg>
          </div>
          <div class="landing-steps__subtitle" v-html="slide.subtitle">
          </div>
          <div class="landing-steps__description">
            {{ slide.description }}
          </div>
        </SwiperSlide>
      </Swiper>
    </aos-vue>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from '@SwiperVue';

export default {
  name: 'Steps',
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      slider: [
        {
          subtitle: 'Оптимизация налогов',
          description: 'Все платежи с НДС. Снижение налога на прибыль',
        },
        {
          subtitle: 'Планирование бюджета',
          description: 'Платежи включают все расходы на содержание автопарка',
        },
        {
          subtitle: 'Лояльность персонала',
          description: 'Альтернатива повышения зарплаты сотрудникам',
        },
        {
          subtitle: 'Управленческая гибкость',
          description: 'Без аванса. Возможность выкупа после подписки',
        },
      ],
    };
  }
};
</script>