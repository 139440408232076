<template>
  <Header></Header>
  <main class="landing landing--ul">
    <section class="landing__section--intro">
      <Intro></Intro>
    </section>
    <section id="steps" class="landing__section--steps">
      <Steps></Steps>
    </section>
    <section id="catalog" class="landing__section--catalog">
      <Catalog></Catalog>
    </section>
    <aos-vue placement="top-bottom" :duration="800" animation="fade-up" :once="true">
      <section id="#about" class="landing__section--about">
        <About></About>
      </section>
    </aos-vue>
    <section id="faq" class="landing__section--faq">
      <Faq></Faq>
    </section>
    <aos-vue placement="top-bottom" :duration="800" animation="fade-up" :once="true">
      <section id="coverage" class="landing__section--coverage">
        <Coverage></Coverage>
      </section>
    </aos-vue>
  </main>
  <Footer></Footer>
  <Popup :isShow="showPopup"></Popup>
</template>

<script>
import Header from './components/Header';
import Intro from './components/Intro';
import Steps from './components/Steps';
import Catalog from './components/Catalog';
import About from './components/About';
import Faq from './components/Faq';
import Coverage from './components/Coverage';
import Footer from '@/layouts/Footer';
import Popup from './components/Popup';
import {mapGetters} from 'vuex';

export default {
  name: 'LandingUl',
  components: {
    Header,
    Intro,
    About,
    Steps,
    Catalog,
    Faq,
    Coverage,
    Footer,
    Popup
  },
  computed: {
    ...mapGetters({
      showPopup: 'callUl/show'
    }),
  }
};
</script>