<template>
  <picture>

    <template v-if="mobile">
      <source media="(max-width: 767px)"
        :srcset="`${require(`@/assets/images/landing/${path}-mobile.webp`)}, ${require(`@/assets/images/landing/${path}-mobile@2x.webp`)} 2x`"
        type='image/webp' />
      <source media="(max-width: 767px)"
        :srcset="`${require(`@/assets/images/landing/${path}-mobile.${ext}`)}, ${require(`@/assets/images/landing/${path}-mobile@2x.${ext}`)} 2x`"
        :type='`image/${ext}`'>
    </template>

    <template v-if="tablet">
      <source media="(min-width: 768px) and (max-width: 1024px)"
        :srcset="`${require(`@/assets/images/landing/${path}-tablet.webp`)}, ${require(`@/assets/images/landing/${path}-tablet@2x.webp`)} 2x`"
        type='image/webp' />
      <source media="(min-width: 768px) and (max-width: 1024px)"
        :srcset="`${require(`@/assets/images/landing/${path}-tablet.${ext}`)}, ${require(`@/assets/images/landing/${path}-tablet@2x.${ext}`)} 2x`"
        :type='`image/${ext}`'>
    </template>

    <template v-if="device">
      <source media="(max-width: 1024px)"
        :srcset="`${require(`@/assets/images/landing/${path}-device.webp`)}, ${require(`@/assets/images/landing/${path}-device@2x.webp`)} 2x`"
        type='image/webp' />
      <source media="(max-width: 1024px)"
        :srcset="`${require(`@/assets/images/landing/${path}-device.${ext}`)}, ${require(`@/assets/images/landing/${path}-device@2x.${ext}`)} 2x`"
        :type='`image/${ext}`'>
    </template>

    <source
      :srcset="`${require(`@/assets/images/landing/${path}.webp`)}, ${require(`@/assets/images/landing/${path}@2x.webp`)} 2x`"
      type='image/webp' />
    <img
      :srcset="`${require(`@/assets/images/landing/${path}.${ext}`)}, ${require(`@/assets/images/landing/${path}@2x.${ext}`)} 2x`"
      :alt='name' loading="lazy" class="img">
  </picture>
</template>

<script>

export default {
  name: 'Picture',
  props: {
    name: {
      type: String,
      default: 'Picture'
    },
    path: {
      type: String,
      required: true
    },
    ext: {
      type: String,
      required: true
    },
    mobile: {
      type: Boolean,
      default: false
    },
    tablet: {
      type: Boolean,
      default: false
    },
    device: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  }
};
</script>

<style scoped>
.img {
  width: 100%;
  height: 100%;
}
</style>