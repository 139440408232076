<template>
  <main class="home" role="main">
    <section class="home__section home__section--popup">
      <div v-if="!showConfirmation" class="popup">
        <div class="popup__requisition">
          <div class="popup__content">
            <div class="popup__title">Укажите номер телефона</div>
            <div class="popup__text">Для доступа в личный кабинет</div>
            <form class="popup__form requisition-form" @submit.prevent="regRequest">
              <fieldset class="popup__fieldset" :class="{ 'is-error': v$.form.phone.$error || clientError }">
                <legend class="popup__legend">Телефон*</legend>
                <input class="popup__input" v-model="form.phone" type="tel" name="tel" placeholder="+7***" />
                <div class="popup__error">{{ errorText }}</div>
              </fieldset>
              <div class="popup__note">
                <span class="popup__span">Нажав на&nbsp;кнопку, соглашаюсь на&nbsp;обработку </span>
                <a class="popup__link" href="https://api.autopodpiska.ru/document/personal-data-consent" target="_blank">персональных данных</a>
              </div>
              <div class="popup__buttons" :class="{ 'popup__buttons--disabled': loading.code }">
                <div class="popup__button">
                  <button class="button button" type="submit">Войти</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div v-else class="popup">
        <div class="popup__confirmation">
          <div class="popup__content">
            <div class="popup__title">Подтверждение
            </div>
            <div class="popup__text">Мы отправили вам код подтверждения на номер
              <div class="popup__num">{{ form.phone }}.
                <a href="#" @click="returnToLogin()">Изменить</a>
              </div>
            </div>
            <form class="popup__form requisition-form" @submit.prevent="codeRequest">
              <fieldset class="popup__fieldset" :class="{ 'is-error': smsError }">
                <legend class="popup__legend">Код из SMS</legend>
                <input class="popup__input" type="text" name="SMS" placeholder="****" v-model="sms" />
                <div class="popup__error">Неверный код подтверждения</div>
              </fieldset>
              <div class="popup__note">
                <a v-if="countdown === 0" class="popup__send" href="#" @click.prevent="resendCode">Отправить новое
                  сообщение</a>
                <span v-else class="popup__span">Вы сможете получить новое SMS через 00:{{ countdown }}</span>
              </div>
              <div class="popup__buttons" :class="{ 'popup__buttons--disabled': loading.confirm }">
                <div class="popup__button">
                  <button class="button button--requisition" type="submit">Вход</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import * as links from '../store/links';
import { mapGetters } from 'vuex';

export default {
  name: 'Registration',

  data() {
    return {
      showConfirmation: false,
      userPhone: false,
      form: {
        phone: '',
        url: '',
      },
      countdown: 59,
      countdownIntervalId: '',
      clientError: false,
      smsError: false,
      sms: '',
      hash: '',
      loading: {
        code: false,
        confirm: false
      },
      prevRoute: null,
      errorText: 'Неверный формат телефона',
      errors: {
        'default': 'Неверный формат телефона',
        'user is registered already': 'Пользователь уже зарегистрирован'
      },
    };
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  computed: {
    ...mapGetters({
      token: 'auth/token',
      user: 'auth/user'
    }),
  },
  watch: {
    showConfirmation() {
      if (this.showConfirmation) {
        this.countDownTimer();
      }
    }
  },
  validations() {
    return {
      form: {
        phone: { required },
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  created() {
    if (this.$route.query) {
      if (this.$route.query.hash) {
        let hash = this.$route.query.hash;
        if (hash && this.sanitizeHash(hash)) {
          this.showConfirmation = true;
          this.hash = hash;
        }
      } else {
        this.form.url = window.location.href;
      }
      if (this.$route.query.rclass && this.$route.query.rclass.length) {
        this.$store.dispatch('auth/saveRclass', this.$route.query.rclass);
      }
    }
    this.userPhone = this.getCookie('userPhone');
    if (this.userPhone) {
      this.form.phone = this.userPhone;
      this.deleteCookie('userPhone', `.${links.HOST}`);
    }
    document.documentElement.style.setProperty('overflow', 'hidden');
    document.body.style.setProperty('position', 'relative');

  },
  methods: {
    async regRequest() {

      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) return;

      this.clientError = false;
      console.info('Registration::regRequest');
      this.loading.code = true;

      this.axios.post(
        `${links.API}/registration/start`,
        this.form,
        {
          loading: { message: 'Trying to login...' }
        }
      )
        .then(async (response) => {
          this.hash = response.data.hash;

          if (!this.showConfirmation) {
            this.showConfirmation = true;
          }
          this.loading.code = false;
        })
        .catch((error) => {
          console.error(error);
          this.clientError = true;
          this.loading.code = false;
          console.error(error);
          this.errorText = this.errors['default'];
          if (this.errors[error.response.data.message]) {
            this.errorText = this.errors[error.response.data.message];
          }
        });

    },
    codeRequest() {
      const carId = this.prevRoute?.params.id;
      const variables = {
        code: this.sms,
        hash: this.hash,
      };

      if (carId) {
        variables.car_id = carId;
      }

      if (window._tmr) {
        window._tmr.push({ type: 'reachGoal', id: 3320555, value: 1432, goal: 'FS_nt'});
      }

      this.axios.post(
        `${links.API}/registration/finish`,
        variables,
        {
          loading: { message: 'Trying to check sms...' }
        }
      )
        .then(async (response) => {
          this.$store.dispatch('auth/saveToken', response.data);

          await this.$store.dispatch('auth/fetchUser');

          this.smsError = false;
          this.$router.push('/catalog');
          this.loading.confirm = false;
          if (this.$metrika) {
            this.$metrika.reachGoal('lk_verific_number_fs');

          }
        })
        .catch((error) => {
          console.error(error);
          this.smsError = true;
          this.loading.confirm = false;
        });
    },
    countDownTimer() {
      if (this.countdown > 0) {
        this.countdownIntervalId = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown -= 1;
          }
        }, 1000);
      }
    },
    resendCode() {
      clearInterval(this.countdownIntervalId);
      this.countdown = 59;
      this.countDownTimer();
      this.regRequest();
    },
    returnToLogin() {
      clearInterval(this.countdownIntervalId);
      this.countdown = 59;
      this.$router.push('/registration');
      this.showConfirmation = false;
      this.hash = '';
    },
    sanitizeHash() {
      return true;
    },
    setCookie: function (name, value, exdays, domain) {
      let d, expires;
      expires = exdays || 1;
      d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      expires = 'expires=' + d.toUTCString();
      document.cookie = name + '=' + value + ';' + expires + ';path=/;domain=' + domain;
    },
    getCookie: function (cname) {
      let name = cname + '=';
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },
    deleteCookie: function (name, domain) {
      this.setCookie(name, '', -1, domain);
    },
  },
  beforeUnmount() {
    document.documentElement.style.removeProperty('overflow');
    document.body.style.removeProperty('position');
  }
};
</script>