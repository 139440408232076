<template>
  <header :class="['landing-header', isHide ? 'is-hide' : '', isScroll ? 'is-scroll' : '']">
    <div class="landing-header__content">
      <div class="landing-header__logo">
        <Picture path="icons/logo-autodom" ext="png" name="Logo"></Picture>
      </div>
      <nav class="landing-header__nav">
        <a v-for="item, index in navList" :key="index" :href="item.link" @click.prevent="scrollTo"
          class="landing-header__link">
          {{ item.text }}
        </a>
        <router-link class="landing-header__jur-button" to="/">
          <Button name="Физическим лицам" modifier="border"></Button>
        </router-link>
        <router-link class="landing-header__jur-button for-mobile" to="/">
          <Button name="Физ. лицам" modifier="border"></Button>
        </router-link>
      </nav>
    </div>
  </header>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import Button from './Button';
import Picture from './Picture';

export default {
  name: 'Header',
  components: {
    Button,
    Picture
  },
  data() {
    return {
      lastScrollTop: 0,
      scrollTop: null,
      isHide: false,
      isScroll: false,
      navList: [
        {
          text: 'Как работает',
          link: '#steps',
        },
        {
          text: 'FAQ',
          link: '#faq',
        },
        {
          text: 'Каталог',
          link: '#catalog',
        },
      ]
    };
  },
  created() {
    gsap.registerPlugin(ScrollToPlugin);
  },
  mounted() {
    this.animationInit();
  },
  methods: {
    scrollTo(event) {
      const href = event.target.getAttribute('href');

      if (href === '#catalog') {        
        this.$router.push('/ul/catalog');
        return;
      }

      const selector = document.querySelector(href);

      gsap.timeline().to(window, {
        duration: 1.2,
        scrollTo: selector,
        ease: 'Power3.ease',
      });
    },
    animationInit() {
      window.addEventListener('scroll', () => {
        this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (this.scrollTop > this.lastScrollTop) {
          this.isHide = true;
        } else {
          this.isHide = false;
        }

        if (this.scrollTop > 200) {
          this.isScroll = true;
        } else {
          this.isScroll = false;
        }

        this.lastScrollTop = this.scrollTop <= 0 ? 0 : this.scrollTop;
      }, false);
    }
  }
};
</script>