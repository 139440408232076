<template>
  <section class="home__section home__section--call" id="call">
    <div class="call">
      <div class="call__content">
        <div class="call__description">
          <div class="call__query">Нет подходящего автомобиля?
          </div>
          <div class="call__title">Поможем подобрать<br>то, что нужно
          </div>
          <div class="call__text">Весь автопарк официального дилера к вашим услугам
          </div>
          <div class="call__button">
            <a class="button" href="#" @click.prevent="callBackDialog">Заказать звонок</a>
          </div>
        </div>
        <div class="call__bg">
          <picture>
            <source media="(max-width: 767px)" srcset="@/assets/images/about/slide-1-mobile.webp, @/assets/images/about/slide-1-mobile@2x.webp 2x" type="image/webp"/>
            <source media="(max-width: 767px)" srcset="@/assets/images/about/slide-1-mobile.png, @/assets/images/about/slide-1-mobile@2x.png 2x" type="image/png"/>
            <source media="(min-width: 768px) and (max-width: 1024px)" srcset="@/assets/images/about/slide-1-tablet.webp, @/assets/images/about/slide-1-tablet@2x.webp 2x" type="image/webp"/>
            <source media="(min-width: 768px) and (max-width: 1024px)" srcset="@/assets/images/about/slide-1-tablet.png, @/assets/images/about/slide-1-tablet@2x.png 2x" type="image/png"/>
            <source srcset="@/assets/images/about/slide-1.webp, @/assets/images/about/slide-1@2x.webp 2x" type="image/webp"/><img srcset="@/assets/images/about/slide-1.png, @/assets/images/about/slide-1@2x.png 2x" alt="Picture" loading="lazy" width="100%" height="100%"/>
          </picture>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OfferCall',
  methods: {
    callBackDialog() {
      this.$store.dispatch('call/show', true);
      if (this.$metrika) {
        this.$metrika.reachGoal('lk_callback_request');
      }
    }
  },
};
</script>