import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import store from '../store';

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    window.scrollTo(0,0);
  },
  routes,
});

const addDescription = (to) => {
  if (!document.querySelector('meta[name="description"]')) {
    const head = document.querySelector('head');
    const metaDescription = document.createElement('meta');

    metaDescription.setAttribute('name', 'description');
    head.appendChild(metaDescription);
  }
  if (to.meta.description) {
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description);
  }
};

const addKeywords = (to) => {
  if (!document.querySelector('meta[name="keywords"]')) {
    const head = document.querySelector('head');
    const metaKeywords = document.createElement('meta');

    metaKeywords.setAttribute('name', 'keywords');
    head.appendChild(metaKeywords);
  }
  if (to.meta.keywords) {
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.keywords);
  }
};

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let token = store.getters['auth/token'];
    if (token === null) {
      next({
        path: '/registration',
      });
    } else {
      let user = store.getters['auth/user'];
      if (user && !user.car_id && to.path === '/profile') {
        next({
          path: '/scoring',
        });
      } else {
        document.title = to.name;
        addDescription(to);
        addKeywords(to);
        next();
      }
    }
  } else {
    document.title = to.name;
    addDescription(to);
    addKeywords(to);
    next(); // make sure to always call next()!
  }
});

export default router;
