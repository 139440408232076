<template>
  <swiper @swiper="setSwiper" v-if="slides?.length" class="card__slider" :slides-per-view="1" :space-between="0">
    <swiper-slide class="card__slide" v-for="img in slides" :key="img">
      <picture>
        <img :src="img" alt="Car" title="" />
      </picture>
    </swiper-slide>
    <div v-if="slides.length > 1" class="card__control">
      <card-slider-prev :swiper="cardSwiper" />
      <card-slider-pagination :length="slides.length" />
      <card-slider-next :swiper="cardSwiper" />
    </div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from '@SwiperVue';
import CardSliderNext from './card-slider/CardSliderNext';
import CardSliderPrev from './card-slider/CardSliderPrev';
import CardSliderPagination from './card-slider/CardSliderPagination';
import { ref } from 'vue';

export default {
  name: 'CardSlider',
  components: {
    CardSliderPagination,
    CardSliderPrev,
    CardSliderNext,
    Swiper,
    SwiperSlide
  },
  props: {
    slides: {},
  },
  data() {
    return {
      sliderSwiper: {},
    };
  },
  setup() {
    const cardSwiper = ref(null);

    const setSwiper = (swiper) => {
      cardSwiper.value = swiper;
    };

    return {
      cardSwiper,
      setSwiper
    };
  },
};
</script>