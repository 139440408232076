<template>
  <div class="catalog__select-menu" v-click-outside="hideMenu">
    <div v-if="isDesktop" class="catalog__select" @click="showMenu">
      <span>{{ captions[filtersSelect] }}</span>
    </div>
    <transition name="dropdown">
      <div v-show="showDropdown" class="catalog__dropdown">
        <ul>
          <li @click="chooseOption('price-asc')">Возрастанию</li>
          <li @click="chooseOption('price-desc')">Убыванию</li>
        </ul>
      </div>
    </transition>
    <div v-if="!isDesktop" class="catalog__select">
      <select v-model="filtersSelect">
        <option selected hidden value="default">Сортировать по</option>
        <option value="price-asc">Возрастанию</option>
        <option value="price-desc">Убыванию</option>
      </select>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3';

export default {
  name: 'CatalogSelect',
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      filtersSelect: 'default',
      showDropdown: false,
      windowWidth: window.innerWidth,
      captions: {
        'default': 'Сортировать по',
        'price-asc': 'Возрастанию',
        'price-desc': 'Убыванию'
      }
    };
  },
  computed: {
    isDesktop() {
      return this.windowWidth > 1024;
    }
  },
  methods: {
    sendSorting(val) {
      this.$emit('sortCards', val);
    },
    showMenu() {
      this.showDropdown = true;
    },
    hideMenu() {
      this.showDropdown = false;
    },
    chooseOption(val) {
      this.hideMenu();
      this.filtersSelect = val;
    }
  },
  watch: {
    filtersSelect(val) {
      this.sendSorting(val);
    }
  },
  created() {
    this.sendSorting('id');
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  }
};
</script>