<template>
  <div class="landing-intro">
    <div class="landing-intro__content">
      <div class="landing-intro__title">
        <div class="landing-intro__primary">Автоподписка.</div>
        <div class="landing-intro__one is-hidden">
          <span v-for="item, index in one" :key="index" v-html="item">
          </span>
        </div>
        <div class="landing-intro__two is-hidden">
          <span v-for="item, index in two" :key="index" v-html="item">
          </span>
        </div>
        <div class="landing-intro__three is-hidden">
          <span v-for="item, index in three" :key="index" v-html="item">
          </span>
        </div>
      </div>
      <div class="landing-intro__text">
        Для ваших сотрудников
      </div>
      <div class="landing-intro__button-container">
        <div class="landing-intro__button">
          <Button href="#catalog" @click.prevent="scrollTo" name="Выбрать авто"></Button>
        </div>
        <div class="landing-intro__button">
          <Button @click="callUlShow" modifier="information" name="Как работает?"></Button>
        </div>
      </div>
    </div>
    <div ref="circle" class="landing-intro__circle"></div>
    <div class="landing-intro__element landing-intro__element--1">
      <div ref="car1" class="landing-intro__car">
        <Picture mobile tablet :path="`cars/${utmList[0]}/car`" ext="png">
        </Picture>
        <div ref="wheel1" :class="['landing-intro__wheel', utmList[0]]">
          <Picture :path="`cars/${utmList[0]}/wheel`" ext="png">
          </Picture>
        </div>
      </div>
    </div>
    <div class="landing-intro__element landing-intro__element--2">
      <div ref="car2" class="landing-intro__car">
        <Picture mobile tablet :path="`cars/${utmList[1]}/car`" ext="png">
        </Picture>
        <div ref="wheel2" :class="['landing-intro__wheel', utmList[1]]">
          <Picture :path="`cars/${utmList[1]}/wheel`" ext="png">
          </Picture>
        </div>
      </div>
    </div>
    <div class="landing-intro__element landing-intro__element--3">
      <div ref="car3" class="landing-intro__car">
        <Picture mobile tablet :path="`cars/${utmList[2]}/car`" ext="png">
        </Picture>
        <div ref="wheel3" :class="['landing-intro__wheel', utmList[2]]">
          <Picture :path="`cars/${utmList[2]}/wheel`" ext="png">
          </Picture>
        </div>
      </div>
    </div>
    <div class="landing-intro__service">
      <div class="landing-intro__query">
        Подписка включает
      </div>
      <Swiper class="landing-intro__slider" slidesPerView="auto">
        <SwiperSlide class="landing-intro__slide" v-for="slide, index in slider" :key="index">
          <div class="landing-intro__icon">
            <Picture :path="`icons/service-${slide.icon}`" ext="png" name="Icon"></Picture>
          </div>
          <div class="landing-intro__subtitle" v-html="slide.text">
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { Swiper, SwiperSlide } from '@SwiperVue';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import Button from './Button';
import Picture from './Picture';

export default {
  name: 'Intro',
  components: {
    Button,
    Picture,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      introTitleIndex: 1,
      title: undefined,
      one: ['Д', 'л', 'я&nbsp;', 'б', 'и', 'з', 'н', 'е', 'с', 'а'],
      two: ['У', 'д', 'о', 'б', 'н', 'е', 'е&nbsp;', 'л', 'и', 'з', 'и', 'н', 'г', 'а'],
      three: ['Б', 'е', 'з&nbsp;', 'з', 'а', 'б', 'о', 'т'],
      utmList: [
        'omoda_c5',
        'haval_f7',
        'geely_tugella',
      ],
      utmListI: [],
      slider: [
        {icon: 2, text: 'Техническое обслуживание'},
        {icon: 3, text: 'Страховка КАСКО&nbsp;и&nbsp;ОСАГО'},
        {icon: 4, text: 'Хранение и замена шин'},
        {icon: 7, text: 'Телематический <br>блок'},
      ],
    };
  },
  created() {
    gsap.registerPlugin(ScrollToPlugin);
  },
  mounted() {
    this.introInit();
  },
  beforeUnmount() {
    this.introTitleDestroy();
  },
  methods: {
    callUlShow() {
      this.$store.dispatch('callUl/show', {show: true, type: 'information', carId: 0});
    },
    scrollTo(event) {
      const href = event.target.getAttribute('href');
      const selector = document.querySelector(href);

      gsap.timeline().to(window, {
        duration: 1.2,
        scrollTo: selector,
        ease: 'Power3.ease',
      });
    },
    introInit() {
      const introTitleInit = this.introTitleInit;
      const tl = gsap.timeline();

      const header = document.querySelector('header');
      header.classList.add('is-hidden');

      tl
        .to(this.$refs.circle, {
          onStart() {
            window.scrollTo(0, 0);
            disablePageScroll();
          },
          onComplete() {
            introTitleInit();
          },
          duration: 2,
          ease: 'power4.out',
          scale: '1',
        })
        .from(this.$refs.car1, {
          duration: 1,
          ease: 'power2.out',
          x: '100%',
          clearProps: 'all',
        })
        .from(this.$refs.wheel1, {
          duration: 1,
          ease: 'power2.out',
          rotate: '360',
          clearProps: 'all',
        }, '<')
        .from(this.$refs.car2, {
          duration: 1,
          ease: 'power2.out',
          x: '100%',
          clearProps: 'all',
        }, '-=50%')
        .from(this.$refs.wheel2, {
          duration: 1,
          ease: 'power2.out',
          rotate: '360',
          clearProps: 'all',
        }, '<')
        .from(this.$refs.car3, {
          duration: 1,
          ease: 'power2.out',
          x: '100%',
          clearProps: 'all',
        }, '-=50%')
        .from(this.$refs.wheel3, {
          onComplete() {
            enablePageScroll();
          },
          duration: 1,
          ease: 'power2.out',
          rotate: '360',
          clearProps: 'all',
        }, '<')
        .from(header, {
          onStart() {
            header.classList.remove('is-hidden');
          },
          duration: 0.3,
          ease: 'power2.out',
          y: '-100%',
          clearProps: 'all',
        });
    },
    introTitleInit() {
      const tl = gsap.timeline();
      const introTitleInit = this.introTitleInit;

      if (this.introTitleIndex === 1) {
        this.title = document.querySelector('.landing-intro__one');
        this.introTitleIndex = 2;
      } else if (this.introTitleIndex === 2) {
        this.title = document.querySelector('.landing-intro__two');
        this.introTitleIndex = 3;
      } else if (this.introTitleIndex === 3) {
        this.title = document.querySelector('.landing-intro__three');
        this.introTitleIndex = 1;
      }

      if (!this.title) {
        return;
      }

      const span = this.title.querySelectorAll('span');

      span.forEach((el) => {
        const title = this.title;
        tl
          .from(el, {
            onStart() {
              title.classList.remove('is-hidden');
            },
            duration: 0.2,
            ease: 'power2.out',
            opacity: 0,
            x: '150%',
            clearProps: 'all',
          });
      });

      const title = this.title;

      tl.to(this.title, {
        onComplete() {
          introTitleInit();
          title.classList.add('is-hidden');
        },
        delay: 0.9,
        duration: 0.4,
        opacity: 0,
        x: '-50',
        clearProps: 'all',
      });
    },
    introTitleDestroy() {
      const titleOne = document.querySelector('.landing-intro__one') ?? false;
      const titleTwo = document.querySelector('.landing-intro__two') ?? false;
      const titleThree = document.querySelector('.landing-intro__three') ?? false;
      const span = document.querySelectorAll('.landing-intro__one span, .landing-intro__two span, .landing-intro__three span');

      span.forEach((el) => {
        gsap.killTweensOf(el);
      });

      gsap.killTweensOf([titleOne, titleTwo, titleThree]);
      
      titleOne.classList.add('is-hidden');
      titleTwo.classList.add('is-hidden');
      titleThree.classList.add('is-hidden');
    }
  }
};
</script>