<template>
  <router-link class="back" to="/ul/catalog">
    <div class="back__icon">
      <svg>
        <use xlink:href="@/assets/images/sprites.svg#arrow-back"></use>
      </svg>
    </div>
    <div class="back__text">Обратно к каталогу
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'BtnBackUl',
};
</script>