import { createStore } from 'vuex';
import auth from './modules/auth';
import call from './modules/call';
import callUl from './modules/callUl';
import cars from './modules/cars';
import loader from './modules/loader';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    call,
    callUl,
    cars,
    loader
  }
});
